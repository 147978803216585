import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getAnalysisLists } from '../utils/requestDelivery';
import { getStoreIds } from '../utils/extra';
import DataGridTw from '../utils/datagridtw';
import Loadeffect from '../components/Loadeffect';
import Linkbutton from '../components/Linkbutton';
import Tooltip from '../components/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarQuickFilter,
	GridActionsCellItem,
} from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import NumbersIcon from '@mui/icons-material/Numbers';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DevicesIcon from '@mui/icons-material/Devices';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

export default function Analysis() {
	const userVal = useSelector((state) => state.users);
	const userLogged = userVal.storeName;
	// const storeIDs = userVal.value.attributes.nickname.split(',');
	const zoneInfo = userVal.zone;
	const hq = userVal.hq;
	const userName = userVal.userName;
	const [storeIDs, setStoreIDs] = useState([userName]);
	const [deliveryList, updateDeliveryList] = useState([]);
	const [deliveryBar, updateDeliveryBar] = useState([]);
	const [driverBar, updateDriverBar] = useState([]);
	const [open, setOpen] = useState(false);
	const [pickerOn, setPickerOn] = useState(false);
	const [onload, setOnload] = useState(true);
	const [fromValue, setFromValue] = useState(moment().weekday(1));
	const [toValue, setToValue] = useState(moment());
	const [rows, setRows] = useState([]);
	const [rowsReturn, setRowsReturn] = useState([]);
	const columns = [
		{
			field: '查詢',
			headerName: '查詢',
			type: 'actions',
			width: 30,
			cellClassName: 'actions',
			getActions: (params) => {
				const { row, id } = params;
				const timeVal = moment(row.time).format('YYYY-MM-DD');
				const fromVal = moment(row.time).subtract(1, 'days').format('YYYY-MM-DD');
				return [
					<GridActionsCellItem
						icon={
							<Tooltip title='檢視訂單'>
								<TroubleshootIcon color='primary' />
							</Tooltip>
						}
						label='檢視訂單'
						onClick={(e) =>
							window.open(
								`https://direct.uber.com/accounts/ba0f5d50-9ffc-4f28-8db6-171ebddc16e0?locationUuid=01cfa8a8-569f-59fa-98bb-356a8ea2546d&searchSelectId=SEARCH_OPTION_EXTERNAL_ORDER_ID&searchInputValue=${id}&searchStartTime=${fromVal}T16%3A00%3A00.000Z&searchEndTime=${timeVal}T15%3A59%3A59.999Z`,
								'_blank'
							)
						}
					/>,
				];
			},
		},
		{ field: 'storeId', headerName: '門店', width: 150 },
		{ field: 'time', headerName: '訂單時間', width: 180 },
		{ field: 'orderType', headerName: '訂單類別', width: 100 },
		{ field: 'orderFrom', headerName: '訂單來源', width: 100 },
		{ field: 'orderNo', headerName: '訂單編號', width: 230 },
		{ field: 'amount', headerName: '訂單金額', width: 100 },
		{ field: 'fee', headerName: '運費', width: 100 },
		{ field: 'returnFee', headerName: '返程運費', width: 100 },
		{ field: 'cups', headerName: '杯數', width: 100 },
		// { field: 'delType', headerName: '配送方式', width: 300 },
		{ field: 'address', headerName: '配送地址', width: 300 },
	];

	useEffect(() => {
		if (onload) {
			onQuery(fromValue.format('YYYYMMDD'), toValue.format('YYYYMMDD'));
			setOnload(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLogged]);

	async function onQuery(from, to) {
		if (from <= to) {
			setOpen(true);
			let delVal = [0, 0, 0, 0, 0, 0, 0];
			let driVal = [0, 0, 0, 0, 0, 0, 0];
			let row = [];
			let rowReturn = [];
			let delList = [];
			let queryDate = from;
			let sid = hq ? await getStoreIds(hq, zoneInfo, userName, '/analysis', 'id') : storeIDs;
			const reqstoreIDs = hq ? ['HQ'] : sid;
			for (let i = 0; i <= moment(to).diff(moment(from), 'days'); i++) {
				queryDate = moment(from).add(i, 'days').format('YYYYMMDD');
				// const reqstoreIDs = storeIDs[0] === 'HQ' ? [storeIDs[0]] : storeIDs;
				await getAnalysisLists(reqstoreIDs, queryDate).then((res) => {
					if (hq && zoneInfo !== 'master' && sid.length >= 1) {
						res[0] = res[0].filter((item) => sid.includes(item.store_id));
					}
					res[0].map((i) => {
						delList.push(i);
						let payType = i.pay_state === '未支付' ? '現金單' : '非現金單';
						let cups = 0;
						i.items.map((item) => (cups += parseInt(item.split('@')[1])));
						let tableVal = {
							id: i.id,
							storeId: i.pickup_name,
							time: moment(i.createdAt).format('YYYY/MM/DD HH:mm:ss'),
							orderType: payType,
							orderFrom: i.order_from,
							orderNo: i.id,
							amount: i.mace_total,
							cups: cups,
							fee: i.fee * 0.01,
							address: i.dropoff_address,
						};
						if (i.status === 'delivered') {
							row.push(tableVal);
							delVal[moment(i.updatedAt).isoWeekday() - 1] += 1;
							driVal[moment(i.updatedAt).isoWeekday() - 1] += i.drivers || 0;
						} else if (i.status === 'returned' || i.status === 'hide') {
							tableVal.returnFee = i.fee * 0.01;
							rowReturn.push(tableVal);
						}
						return i;
					});
				});
			}
			updateDeliveryList(delList);
			updateDeliveryBar(delVal);
			updateDriverBar(driVal);
			setRows(row);
			setRowsReturn(rowReturn);
			setOpen(false);
			setStoreIDs(sid);
		}
	}

	function StatsCards() {
		let cardTitle = ['總配送次數', '司機總數', '現金單總額', '非現金單總額', '總運費', '食在麻吉單數', '電話單數'];
		let cardIcon = [
			<NumbersIcon />,
			<DeliveryDiningIcon />,
			<LocalAtmIcon />,
			<CreditCardIcon />,
			<AttachMoneyIcon />,
			<DevicesIcon />,
			<LocalPhoneIcon />,
		];
		let colorCode = ['#264653', '#2A9D8F', '#E76F51', '#EC8C74', '#F0A390', '#F4A261', '#E9C46A'];
		let counts = [0, 0, 0, 0, 0, 0, 0];
		let cups = 0;
		deliveryList.map((i) => {
			if (i.status === 'delivered') {
				counts[0] += 1;
				counts[1] += i.drivers;
				counts[4] += i.fee * 0.01;
				i.items.map((item) => (cups += parseInt(item.split('@')[1])));
				i.pay_state === '未支付' ? (counts[2] += i.mace_total) : (counts[3] += i.mace_total);
				i.order_from === '電話' ? (counts[6] += 1) : (counts[5] += 1);
			} else if (i.status === 'returned' || i.status === 'hide') {
				counts[4] += i.fee * 0.01 * 2;
			}
			return i;
		});

		return (
			<List>
				{cardTitle.map((i, index) => (
					<ListItem alignItems='flex-start' key={i + index}>
						<ListItemAvatar>
							<Avatar alt={i} sx={{ bgcolor: colorCode[index] }}>
								{cardIcon[index]}
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={
								<Typography sx={{ fontSize: 14 }} color='text.secondary'>
									{i}
								</Typography>
							}
							secondary={
								<Typography sx={{ display: 'inline' }} variant='h5' color='text.primary'>
									{index === 2 || index === 3 || index === 4 ? '$' : ''}
									{counts[index].toLocaleString()}
								</Typography>
							}
						/>
					</ListItem>
				))}
			</List>
		);
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarExport
					csvOptions={{
						fileName: '歷史配送-' + fromValue.format('YYYYMMDD') + '-' + toValue.format('YYYYMMDD'),
						utf8WithBom: true,
					}}
				/>
				<GridToolbarQuickFilter />
			</GridToolbarContainer>
		);
	}

	return (
		<React.Fragment>
			<Loadeffect state={open} />
			<Stack direction={{ sx: 'column', md: 'row' }} spacing={2} paddingBottom={2}>
				<DatePicker
					label='開始'
					value={fromValue}
					onChange={(newValue) => setFromValue(newValue)}
					onAccept={() => setPickerOn(true)}
					// onAccept={(newValue) => onQuery(newValue.format('YYYYMMDD'),toValue.format('YYYYMMDD'))}
					sx={{ backgroundColor: 'white', marginTop: 2 }}
					dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')}
					disableFuture
				/>
				<DatePicker
					label='結束'
					open={pickerOn}
					value={toValue}
					minDate={fromValue}
					onChange={(newValue) => setToValue(newValue)}
					onOpen={() => setPickerOn(true)}
					onClose={() => setPickerOn(false)}
					// onAccept={(newValue) => onQuery(fromValue.format('YYYYMMDD'),newValue.format('YYYYMMDD'))}
					sx={{ backgroundColor: 'white', marginTop: 2 }}
					dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')}
					disableFuture
				/>
				<Button
					variant='outlined'
					startIcon={<QueryStatsIcon />}
					size='large'
					sx={{ marginTop: 2 }}
					onClick={() => onQuery(fromValue.format('YYYYMMDD'), toValue.format('YYYYMMDD'))}>
					搜尋
				</Button>
			</Stack>
			<Grid container>
				<Grid item xs={12} md={3}>
					<Card>
						<CardHeader title='配送統計 (完成)' />
						<Divider />
						<StatsCards />
						<Divider />
						<Linkbutton
							position='center'
							variant='contained'
							color='primary'
							sx={{ marginTop: 1, marginBottom: 1, fontWeight: 'bold' }}
							icon={<RequestQuoteIcon />}
							link={'https://direct.uber.com/accounts/ba0f5d50-9ffc-4f28-8db6-171ebddc16e0/billing'}
							text='Direct 帳單'
						/>
					</Card>
				</Grid>
				<Grid item xs={12} md={9} paddingLeft={{ md: 2 }} paddingTop={{ xs: 2, md: 0 }}>
					<Card>
						<CardHeader title='配送完成' />
						<Divider />
						<CardContent>
							<div style={{ height: '596px', width: '100%' }}>
								<DataGrid
									rows={rows}
									columns={columns}
									sortModel={[{ field: 'time', sort: 'desc' }]}
									slots={{ toolbar: CustomToolbar }}
									localeText={DataGridTw}
									slotProps={{ pagination: { labelRowsPerPage: '每頁顯示資料數' } }}
									initialState={{
										columns: {
											columnVisibilityModel: {
												returnFee: false,
											},
										},
									}}
								/>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6} paddingTop={2}>
					<Card>
						<CardHeader title='星期 x 配送次數 (完成)' />
						<Divider />
						<CardContent>
							<Chart
								options={{
									chart: { id: 'delivery_Bar' },
									plotOptions: { bar: { horizontal: true } },
									xaxis: { categories: ['週一', '週二', '週三', '週四', '週五', '週六', '週日'] },
								}}
								series={[
									{ name: '配送次數', data: deliveryBar },
									{ name: '司機總數', data: driverBar },
								]}
								type='bar'
								height={'528'}
							/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6} paddingLeft={{ md: 2 }} paddingTop={2}>
					<Card>
						<CardHeader title='配送退回' />
						<Divider />
						<CardContent>
							<div style={{ height: 542, width: '100%' }}>
								<DataGrid
									rows={rowsReturn}
									columns={columns}
									sortModel={[{ field: 'time', sort: 'desc' }]}
									slots={{ toolbar: CustomToolbar }}
									localeText={DataGridTw}
									slotProps={{ pagination: { labelRowsPerPage: '每頁顯示資料數' } }}
								/>
							</div>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
