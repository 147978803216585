import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { getStaffInfo, getStoreBySid, Copyright } from '../utils/extra';
import { updateStaff, updateStore } from '../utils/mutations';
import { addForcePW, addChangePW } from '../redux/authSlice';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function ChangePw({ handleBack, loggout, setTitle, title }) {
	const dispatch = useDispatch();
	const forcePW = useSelector((state) => state.users.forcePW);
	const changePW = useSelector((state) => state.users.changePW);
	const userVal = useSelector((state) => state.users.value);
	const [loading, setLoading] = useState(false);
	const [btnColor, setBtnColor] = useState('black');
	const [errMsg, setErrMsg] = useState();
	const [err, setErr] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
	const handleMouseDownOldPassword = (event) => event.preventDefault();
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => event.preventDefault();

	useEffect(() => {
		setTitle('變更密碼');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function setState() {
		setErr(false);
		setErrMsg();
		setLoading(true);
		setBtnColor('hsl(7, 88%, 48%)');
	}

	const handleNewUser = async (event) => {
		event.preventDefault();
		setState();
		const data = new FormData(event.currentTarget);
		let storeid = userVal.attributes.nickname.split(',');
		let id = '';
		let version = '';
		let oldpw = data.get('oldpassword');
		const user = await Auth.currentAuthenticatedUser();
		if (storeid.length > 1 && storeid[0] === 'HQ') {
			//supervisor
			const res = await getStaffInfo({ userName: { eq: userVal.username } });
			id = res.id;
			version = res._version;
		} else {
			const res = await getStoreBySid(storeid[0]);
			id = res.id;
			version = res._version;
		}
		try {
			if (oldpw === data.get('repassword') || oldpw === data.get('repassword1')) {
				setErrMsg('不可使用舊密碼');
				setErr(true);
			} else if (data.get('repassword') === data.get('repassword1')) {
				await Auth.changePassword(user, oldpw, data.get('repassword1'));
				const forceItem = {
					id: id,
					_version: version,
					forcePW: false,
				};
				if (storeid.length > 1 && storeid[0] === 'HQ') {
					//supervisor
					try {
						await updateStaff(forceItem);
					} catch (updateError) {
						console.log('Update error:', updateError);
					}
				} else {
					try {
						await updateStore(forceItem);
					} catch (updateError) {
						console.log('Update error:', updateError);
					}
				}
				dispatch(addForcePW(false));
				dispatch(addChangePW(false));
				setTitle('請使用新密碼登入');
			} else {
				setErrMsg('密碼不一致');
				setErr(true);
			}
		} catch (error) {
			switch (error.code) {
				case 'InvalidPasswordException':
					setErrMsg('密碼長度需要至少8個字元．須包含字母與數字');
					break;
				case 'NotAuthorizedException':
					setErrMsg('舊密碼不正確');
					break;
				case 'LimitExceededException':
					setErrMsg('錯誤密碼的次數超過限制，請稍後再試');
					break;
				default:
					setErrMsg(error.message);
					break;
			}
			setErr(true);
		}
		setLoading(false);
		setBtnColor('black');
	};

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Box>
					<Avatar sx={{ m: 1, bgcolor: 'black' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography fontWeight='bold'>{title}</Typography>
				</Box>
				<Box hidden={!forcePW} component='form' onSubmit={handleNewUser} noValidate sx={{ mt: 1 }}>
					<Grid container>
						<Grid item md={12} xs={12} pt={2}>
							<FormControl variant='outlined' sx={{ display: 'block' }} size='small' error={err}>
								<InputLabel htmlFor='outlined-adornment-newpassword'>舊密碼</InputLabel>
								<OutlinedInput
									id='outlined-adornment-oldpassword'
									type={showOldPassword ? 'text' : 'password'}
									fullWidth
									required
									name='oldpassword'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle oldpassword visibility'
												onClick={handleClickShowOldPassword}
												onMouseDown={handleMouseDownOldPassword}
												edge='end'>
												{showOldPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label='新密碼'
								/>
							</FormControl>
						</Grid>
						<Grid item md={12} xs={12} pt={2}>
							<FormControl variant='outlined' sx={{ display: 'block' }} size='small' error={err}>
								<InputLabel htmlFor='outlined-adornment-repassword'>新密碼</InputLabel>
								<OutlinedInput
									id='outlined-adornment-repassword'
									type={showPassword ? 'text' : 'password'}
									fullWidth
									required
									name='repassword'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle repassword visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label='新密碼'
								/>
							</FormControl>
						</Grid>
						<Grid item md={12} xs={12} pt={2}>
							<FormControl variant='outlined' sx={{ display: 'block' }} size='small' error={err}>
								<InputLabel htmlFor='outlined-adornment-repassword1'>確認新密碼</InputLabel>
								<OutlinedInput
									id='outlined-adornment-repassword1'
									type={showPassword ? 'text' : 'password'}
									fullWidth
									required
									name='repassword1'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle repassword1 visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label='新密碼'
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Typography variant='caption'>密碼需要至少8個字元．須包含字母與數字</Typography>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{
							mt: 2,
							mb: 0,
							bgcolor: btnColor,
							'&:hover': { bgcolor: btnColor },
							'&:press': { bgcolor: btnColor },
						}}>
						<Typography sx={{ color: 'white', display: !loading ? undefined : 'none' }}>更新密碼</Typography>
						<CircularProgress size={24} sx={{ color: 'white', display: loading ? undefined : 'none' }} />
					</Button>
					<Button
						onClick={handleBack}
						fullWidth
						variant='contained'
						sx={{ display: changePW ? undefined : 'none', mt: 2, mb: 0 }}>
						<Typography sx={{ color: 'white' }}>返回</Typography>
					</Button>
					<Button
						onClick={() => loggout() && setTitle('登入')}
						fullWidth
						variant='outlined'
						color='inherit'
						sx={{ mt: 2, mb: 0 }}>
						登出
					</Button>
					<Typography color='error' pt={2} fontSize={14} fontWeight={'bold'}>
						{errMsg}
					</Typography>
				</Box>
				<Box>
					<Copyright mt={4} mb={4} />
				</Box>
			</Box>
		</Container>
	);
}
