import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import Typography from '@mui/material/Typography';

export const listStore = async () => {
	let list = [];
	let variables = {
		limit: 500,
	};
	let hasNextPage = true;
	try {
		while (hasNextPage) {
			const { data: res } = await API.graphql({
				query: queries.listMacuStores,
				variables,
			});
			const { items: item, nextToken } = res.listMacuStores;
			variables.nextToken = nextToken;
			list = list.concat(item);
			hasNextPage = nextToken ? true : false;
		}
	} catch (error) {
		console.log('error', error);
	}
	return list;
};

// async function signUp(data) {
// 	try {
// 		await Promise.all(
// 			data.map(async (d) => {
// 				const { user } = await Auth.signUp({
// 					username: d.sid,
// 					password: 'macu1234',
// 					attributes: {
// 						name: d.name,
// 						nickname: d.sid,
// 						address: d.address
// 					}
// 				});
// 				console.log(user);
// 			})
// 		);
// 	} catch (error) {
// 		console.log('error signing up:', error);
// 	}
// }

export async function listStaff() {
	let list = [];
	let variables = {
		limit: 500,
	};
	let hasNextPage = true;
	try {
		while (hasNextPage) {
			const { data: res } = await API.graphql({
				query: queries.listStaffInfos,
				variables,
			});
			const { items: item, nextToken } = res.listStaffInfos;
			variables.nextToken = nextToken;
			list = list.concat(item);
			hasNextPage = nextToken ? true : false;
		}
	} catch (error) {
		console.log('error', error);
	}
	return list;
}

export async function getStaff(username) {
	let item;
	let variables = {
		limit: 500,
		filter: {
			userName: { eq: username },
		},
	};
	try {
		const { data: res } = await API.graphql({
			query: queries.listStaffInfos,
			variables,
		});
		item = res.listStaffInfos.items[0];
	} catch (error) {
		console.log('error', error);
	}
	return item;
}

export async function getStoreIds(hq, zoneInfo, userName, route, data) {
	let store = data === 'id' ? ['HQ'] : ['總部'];
	if (hq && zoneInfo !== 'master') {
		let item = await getStaff(userName);
		store = await item.storeList
			.map((s) => JSON.parse(s))
			.filter((f) => f.pages.includes(route))
			.map((i) => (data === 'id' ? i.store_id : i.store_name));
	}
	return store;
}

export async function getStoreDrive(hq, zoneInfo, userName, route) {
	let store = [];
	if (hq && zoneInfo !== 'master') {
		let item = await getStaff(userName);
		store = item.storeList
			.map((s) => JSON.parse(s))
			.filter((f) => f.pages.includes(route))
			.map((i) => `${i.store_id}_${i.store_name}`);
	}
	return store;
}

export async function getStaffStore(staffName) {
	let storeList, id, _version;
	let variables = {
		limit: 500,
		filter: {
			staffName: { eq: staffName },
		},
	};
	try {
		const { data: res } = await API.graphql({
			query: queries.listStaffInfos,
			variables,
		});
		({ storeList, id, _version } = res.listStaffInfos.items[0]);
	} catch (error) {
		console.log('error', error);
	}
	return [storeList, id, _version];
}

export function Copyright(props) {
	return (
		<Typography variant='body2' color='text.secondary' align='center' {...props}>
			{'Copyright © '}
			{'麻古茶坊股份有限公司 '}
			{new Date().getFullYear()}
		</Typography>
	);
}

export async function listPermission() {
	let list = [];
	let variables = {
		limit: 500,
	};
	let hasNextPage = true;
	try {
		while (hasNextPage) {
			const { data: res } = await API.graphql({
				query: queries.listPermissions,
				variables,
			});
			const { items: item, nextToken } = res.listPermissions;
			variables.nextToken = nextToken;
			list = list.concat(item);
			hasNextPage = nextToken ? true : false;
		}
	} catch (error) {
		console.log('error', error);
	}
	return list;
}

export const sleep = (i, ms) => new Promise((r) => setTimeout(r, i * ms));

export const structureAddress = (address) => {
	const taiwanAddressRegex =
		/^(?<zip_code>\d{0,6})?(?<state>[^縣市]+[縣市])(?<city>\D+?(市區|鎮區|鎮市|[鄉鎮市區]))(?<street>.+[路|街|大道|巷|弄|里|鄰|道|段])(?<number>\d+(-\d+)?號)(?<floor>.*)$/;
	// /^(?<zip_code>\d{0,6})?(?<state>[^\d\s]+[市縣])(?<city>[^\d\s]+[區鄉鎮市])(?<street>.+((路|大道(\d*段))?|街|大道|巷|弄|里|鄰|道))(?<number>\d+(-\d+)?號)(?<floor>.*)$/;

	const match = address.match(taiwanAddressRegex);
	if (match && match.groups) {
		return {
			structured: JSON.stringify({
				// street_address: [(match.groups.street_address || '').trim(), (match.groups.floor || '').trim()].filter(Boolean),
				street_address: [match.groups.street + match.groups.number, match.groups.floor.trim()],
				state: match.groups.state || '',
				city: match.groups.city || '',
				zip_code: match.groups.zip_code || '',
			}),
			raw: match.groups,
		};
	} else {
		return null;
	}
};

export const getStoreBySid = async (sid) => {
	try {
		const res = await API.graphql({
			query: queries.storeBySid,
			variables: {
				sid: sid,
			},
		});
		const data = res.data.storeBySid.items[0];
		return data;
	} catch (error) {
		console.log('get store error', error);
	}
};

export const getStaffInfo = async (userName) => {
	try {
		const res = await API.graphql({
			query: queries.listStaffInfos,
			variables: { filter: userName },
		});
		const data = res.data.listStaffInfos.items[0];
		return data;
	} catch (error) {
		console.log('get staff error', error);
	}
};
