import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../App.css';
import routes from '../route.js';
import { addUserSettings } from '../redux/authSlice.js';
import { listPermission, getStaffStore, listStaff } from '../utils/extra.js';
import { updateStaff, updatePermission } from '../utils/mutations.js';
import DataGridTw from '../utils/datagridtw.js';
import { peopleSearch } from '../images/images.js';
import ActionStep from '../components/ActionStep.js';
import CompleteStep from '../components/CompleteStep.js';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import {
	DataGrid,
	useGridApiContext,
	GridCellModes,
	GridToolbarQuickFilter,
	GridToolbarContainer,
} from '@mui/x-data-grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Permission({ setOpen }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userVal = useSelector((state) => state.users);
	const zoneInfo = userVal.value.attributes.zoneinfo;
	const master = zoneInfo === 'master';
	// const given_name = userVal.value.attributes.given_name;
	const staffStores = userVal.staffStores;
	const userSettings = userVal.userSettings;
	const [row, setRow] = useState([]);
	const [ogRow, setOgRow] = useState([]);
	const [col, setCol] = useState([]);
	const [staffList, setStaffList] = useState([]);
	const [storeList, setStoreList] = useState([]);
	const [val, setVal] = useState([]);
	const [options, SetOptions] = useState([]);
	const [action, setAction] = useState();
	const [staffVal, setStaffVal] = useState([]);
	const [updatedRoute, setUpdatedRoute] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [alignment, setAlignment] = useState('store');
	const [selectedAccount, setSelectedAccount] = useState('');
	const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
	const [cellModesModel, setCellModesModel] = useState({});
	const steps = ['選擇部門', '選擇使用者', '完成'];
	const firstCol = [
		{
			field: 'account',
			headerName: '帳號',
			type: 'string',
			width: 200,
			editable: false,
			cellClassName: 'sticky-cell',
		},
	];

	useEffect(() => {
		getPermission();
		setOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		renderCol();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, alignment]);

	useEffect(() => {
		if (action === '帳號權限') {
			getAllPermission();
		}
		if (action === '顯示門店') {
			getDisplay();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [val, action]);

	const handlePaginationModelChange = (newPaginationModel) => setPaginationModel(newPaginationModel);

	const handleChange = (event, newAlignment) => {
		setSelectedAccount('');
		setAlignment(newAlignment);
		setPaginationModel({ ...paginationModel, page: 0 });
		if (newAlignment === 'store') {
			setRow((prev) => {
				prev = ogRow.filter((og) => og.role === undefined);
				return prev;
			});
			SetOptions(storeList);
		} else {
			setRow((prev) => {
				prev = ogRow.filter((og) => og.role !== undefined);
				return prev;
			});
			SetOptions(staffList);
		}
	};

	const nextStep = async (e) => setActiveStep((prevActiveStep) => prevActiveStep + 1);

	const QuickSearchToolbar = () => (
		<GridToolbarContainer>
			<ToggleButtonGroup
				color='primary'
				value={alignment}
				exclusive
				size='small'
				sx={{ display: action === '顯示門店' ? 'none' : undefined }}
				onChange={handleChange}
				aria-label='list'>
				<ToggleButton value='store' disabled={alignment === 'store'}>
					門店
				</ToggleButton>
				<ToggleButton value='hqStaff' disabled={alignment === 'hqStaff'}>
					總部人員
				</ToggleButton>
			</ToggleButtonGroup>
			<GridToolbarQuickFilter
				style={{ display: action === '帳號權限' ? 'none' : undefined }}
				disabled={!selectedAccount && action === '顯示門店'}
				variant='outlined'
				size='small'
				sx={{ width: 200 }}
			/>
		</GridToolbarContainer>
	);

	const getDisplay = async () => {
		let list = await listStaff();
		let staffList = [];
		list.map((n) => staffList.push(n.staffName));
		SetOptions(staffList);
		let rows = [];
		let routeKey = {};
		list.map((store) => {
			let parseList = store.storeList.map((item) => JSON.parse(item));
			parseList.map((s) => {
				userSettings.map((i) => (routeKey[i.route] = s.pages.includes(i.route)));
				rows.push({
					id: s.store_id,
					account: s.store_name,
					...routeKey,
					belongsTo: store.staffName,
				});
				return rows;
			});
			return '';
		});
		setOgRow(rows);
		// if (!master) {
		// 	getStaffInfo(given_name)
		// }
	};

	const getPermission = async () => {
		let permission = await listPermission();
		permission.map((r) => (r.permission = JSON.parse(r.permission)));
		setVal(permission);
	};

	const renderCol = () => {
		let column = [...firstCol];
		routes.map((r) => {
			if (!r.hide) {
				column.push({
					field: r.route,
					headerName: r.name,
					type: 'boolean',
					width: 150,
					editable: true,
					renderCell: renderPermission,
					renderEditCell: renderPermissionEditInputCell,
				});
			}
			return column;
		});
		setCol(column);
	};

	const getAllPermission = async () => {
		let rows = [];
		let storeList = [];
		staffStores.map((n) => {
			let routeKey = {};
			userSettings.map(
				(i) => (routeKey[i.route] = i.permission.store.includes(n.store_id) || i.permission.store[0] === 'ALL')
			);
			rows.push({
				id: n.store_id,
				account: n.store_name,
				...routeKey,
			});
			storeList.push(n.store_name);
			return storeList;
		});
		setStoreList(storeList);
		setRow(rows);
		SetOptions(storeList);

		let list = await listStaff();
		let staffList = [];
		let staffPermission = [];
		list.map((n) => {
			switch (n.role) {
				case '督導':
					n.role = 'supervisor';
					break;
				case '經理':
					n.role = 'manager';
					break;
				case '總部':
					n.role = 'hq';
					break;
				case '會計':
					n.role = 'accounting';
					break;
				default:
					break;
			}
			let routeKey = {};
			userSettings.map(
				(i) => (routeKey[i.route] = i.permission[n.role].includes(n.userName) || i.permission[n.role][0] === 'ALL')
			);
			staffPermission.push({
				id: n.userName,
				account: n.staffName,
				...routeKey,
				role: n.role,
			});
			staffList.push(n.staffName);
			return staffList;
		});
		setStaffList(staffList);
		setOgRow(JSON.parse(JSON.stringify(rows.concat(staffPermission)))); // concat store and staff accounts
	};

	const renderPermission = (params) => <Checkbox value={params.value} checked={params.value} />;

	function PermissionEditInputCell(props) {
		const { id, value, field, hasFocus } = props;
		const apiRef = useGridApiContext();
		const ref = useRef(null);
		const handleChange = async (event, newValue) => {
			let route = props.field;
			if (action === '帳號權限') {
				let userAction = alignment === 'store' ? 'store' : props.row.role;
				setUpdatedRoute((prev) => {
					if (!prev.includes(route)) {
						prev.push(route);
					}
					return prev;
				});
				let curList = val.filter((v) => v.route === route)[0].permission[userAction];
				let index = val.findIndex((p) => p.route === route);
				if (newValue && !curList.includes(props.id)) {
					curList.push(props.id);
				} else {
					curList.splice(curList.indexOf(props.id), 1);
				}
				apiRef.current.setEditCellValue({ id, field, value: newValue });
				setOgRow((prev) => {
					let i = prev.findIndex((f) => f.id === props.id);
					prev[i][route] = newValue;
					return prev;
				});
				setVal((prev) => {
					prev[index].permission[userAction] = curList;
					return prev;
				});
			} else {
				apiRef.current.setEditCellValue({ id, field, value: newValue });
				let name = props.row.belongsTo;
				const res = await getStaffStore(name);
				let store = res[0].map((s) => JSON.parse(s));
				let storeList = {};
				let changeList = [];
				storeList['staffName'] = name;
				storeList['storeList'] = store;
				storeList['id'] = res[1];
				storeList['_version'] = res[2];
				changeList.push(storeList);
				setOgRow((prev) => {
					let i = prev.findIndex((f) => f.id === props.id && f.belongsTo === props.row.belongsTo);
					prev[i][route] = newValue;
					return prev;
				});
				setStaffVal((prev) => {
					let staffList = prev.map((p) => p.staffName);
					if (!staffList.includes(name)) {
						prev = prev.concat(changeList);
					} // check if the updated account is included in the list
					let staffIndex = prev.findIndex((f) => f.staffName === props.row.belongsTo);
					let i = prev[staffIndex].storeList.findIndex((f) => f.store_id === props.id);
					let curPage = [...prev[staffIndex].storeList[i].pages];
					if (newValue && !curPage.includes(route)) {
						curPage.push(route);
					} else if (!newValue && curPage.includes(route)) {
						curPage.splice(curPage.indexOf(route), 1);
					}
					prev[staffIndex].storeList[i].pages = curPage;
					return prev;
				});
			}
			apiRef.current.stopCellEditMode({ id: props.id, field: route });
		};

		useEnhancedEffect(() => {
			if (hasFocus && ref.current) {
				const input = ref.current.querySelector(`input[value="${value}"]`);
				input?.focus();
			}
		}, [hasFocus, value]);

		return (
			<Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
				<Checkbox
					ref={ref}
					name='permissions'
					precision={1}
					value={value}
					onChange={handleChange}
					checked={props.value}
				/>
			</Box>
		);
	}

	const renderPermissionEditInputCell = (params) => <PermissionEditInputCell {...params} />;

	const handleCellClick = useCallback((params, event) => {
		if (!params.isEditable) {
			return;
		}
		if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
			return;
		}
		setCellModesModel((prevModel) => {
			return {
				// Revert the mode of the other cells from other rows
				...Object.keys(prevModel).reduce(
					(acc, id) => ({
						...acc,
						[id]: Object.keys(prevModel[id]).reduce(
							(acc2, field) => ({
								...acc2,
								[field]: { mode: GridCellModes.View },
							}),
							{}
						),
					}),
					{}
				),
				[params.id]: {
					// Revert the mode of other cells in the same row
					...Object.keys(prevModel[params.id] || {}).reduce(
						(acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
						{}
					),
					[params.field]: { mode: GridCellModes.Edit },
				},
			};
		});
	}, []);

	const handleCellModesModelChange = useCallback((newModel) => {
		setCellModesModel(newModel);
	}, []);

	function rolePage() {
		setAction();
		setRow([]);
		setStaffVal([]);
		setUpdatedRoute([]);
		getPermission();
		setActiveStep(0);
		setAlignment('store');
		setSelectedAccount('');
	}

	function secondStep(e) {
		setAction(e.target.innerText);
		nextStep();
	}

	function submit() {
		nextStep();
		if (action === '帳號權限') {
			let update = [];
			updatedRoute.map((u) => {
				let route = val.filter((v) => v.route.startsWith(u));
				if (route.length > 1) {
					// for routes with subpage
					let newPermission = route.filter((f) => f.route.split('/').length === 2)[0].permission;
					route.map((r) => {
						if (r.route.split('/').length > 2) {
							r.permission = newPermission;
						}
						return '';
					});
				}
				route.map((r) => update.push(r));
				return update;
			});
			update.map(async (item) => {
				let permissionVa = {
					id: item.id,
					_version: item._version,
					permission: JSON.stringify(item.permission),
				};
				const res = await updatePermission(permissionVa);
				return res;
			});
		} else {
			staffVal.map(async (staff) => {
				let staffVa = {
					id: staff.id,
					_version: staff._version,
					storeList: staff.storeList.map((s) => JSON.stringify(s)),
				};
				const res = await updateStaff(staffVa);
				return res;
			});
		}

		setTimeout(async () => {
			let response = await listPermission();
			response.map((r) => (r.permission = JSON.parse(r.permission)));
			dispatch(addUserSettings(response));
			navigate('/audit');
		}, 3000);
	}

	// async function getStaffInfo (name) {
	// const res = await getStaffStore(name);
	// let rows = [];
	// let store = res[0].map(s => JSON.parse(s));
	// let routeKey = {};
	// let storeList = {};
	// let changeList = [];
	// storeList['staffName'] = name;
	// storeList['storeList'] = store;
	// storeList['id'] = res[1];
	// storeList['_version'] = res[2];
	// changeList.push(storeList);=
	// console.log("🚀 ~ getStaffInfo ~ changeList:", changeList)
	// setStaffVal(prev => {
	// 	let staffList = prev.map(p => p.staffName);
	// 	if (!staffList.includes(name)) {
	// 		prev = prev.concat(changeList);
	// 	}
	// 	return prev;
	// });
	// setRow(prev => {
	// 	prev = ogRow.filter(og => og.belongsTo === name)
	// 	return prev;
	// });
	// }

	function filter(e) {
		let select = e.target.innerText;
		if (select) {
			setSelectedAccount(select);
		} else {
			setSelectedAccount('');
		}
		if (action === '顯示門店') {
			if (select) {
				setRow((prev) => {
					prev = ogRow.filter((og) => og.belongsTo === select);
					return prev;
				});
			} else {
				setCellModesModel('view');
				setRow([]);
			}
		}
		if (action === '帳號權限') {
			setRow(ogRow);
			let result;
			if (select) {
				setRow((prev) => {
					result = prev.filter((f) => f.account === select);
					return result;
				});
			} else {
				setRow((prev) => {
					if (alignment === 'store') {
						prev = ogRow.filter((og) => og.role === undefined);
					} else {
						prev = ogRow.filter((og) => og.role !== undefined);
					}
					return prev;
				});
			}
		}
	}

	const CustomNoRowsOverlay = () => (
		<Box pt={6}>
			<img src={peopleSearch} alt='user created' style={{ width: 100, height: 100 }} />
			<Typography fontWeight={'bold'} pt={1} fontSize={14}>
				請先選擇帳號
			</Typography>
		</Box>
	);

	return (
		<Container component='main' sx={{ maxWidth: { md: action ? '100vw' : '50vw', xs: '100vw' } }}>
			<Stepper
				activeStep={activeStep}
				sx={{
					'& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed':
						{ color: '#e93827' },
					pt: 8,
				}}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			{activeStep === 0 && (
				<ActionStep
					imgTitle1='顯示門店'
					imgTitle2='帳號權限'
					secondStep={secondStep}
					hideReturn={false}
					setOpen={setOpen}
				/>
			)}
			{activeStep === 1 && (
				<React.Fragment>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ position: 'relative' }}>
							<Button onClick={() => rolePage()} variant='contained'>
								上一步
							</Button>
						</Box>
						<Box sx={{ flex: '1 1 auto' }} />
						<Box sx={{ position: 'relative' }}>
							<Button
								onClick={() => submit()}
								variant='contained'
								color='success'
								disabled={
									(action === '帳號權限' && updatedRoute.length === 0) ||
									(action === '顯示門店' && staffVal.length === 0)
								}>
								儲存修改
							</Button>
						</Box>
					</Box>
					<Box pt={2}>
						<Autocomplete
							freeSolo
							id='searchValues'
							options={options}
							value={selectedAccount}
							size='small'
							sx={{ width: 260, display: master ? undefined : 'none', backgroundColor: 'white' }}
							getOptionLabel={(option) => option}
							noOptionsText='無符合結果'
							onChange={(e) => filter(e)}
							renderInput={(params) => <TextField {...params} label={'選擇帳號'} />}
						/>
					</Box>
					<Box sx={{ display: 'flex', pt: 2, '& > :not(style)': { width: '100%', height: '60vh' } }}>
						<Paper elevation={3}>
							<Box m={1}>
								<DataGrid
									rows={row}
									columns={col}
									rowHeight={45}
									disableColumnMenu
									disableColumnFilter
									sx={{ height: '55vh' }}
									paginationModel={paginationModel}
									onPaginationModelChange={handlePaginationModelChange}
									slots={{ noRowsOverlay: CustomNoRowsOverlay, toolbar: QuickSearchToolbar }}
									cellModesModel={cellModesModel}
									onCellModesModelChange={handleCellModesModelChange}
									onCellClick={handleCellClick}
									localeText={DataGridTw}
									pageSizeOptions={[5, 10, 20]}
									slotProps={{ pagination: { labelRowsPerPage: '每頁顯示' } }}
								/>
							</Box>
						</Paper>
					</Box>
				</React.Fragment>
			)}
			{activeStep === 2 && <CompleteStep finishTitle='修改成功！' />}
		</Container>
	);
}
