import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { compareInvoice, InvoiceTitle, DifInvoice } from './util';
import { createCash, updateCash } from '../utils/mutations';
import { allStore } from '../utils/requestDelivery';
import { getStoreIds } from '../utils/extra';
import Loadeffect from '../components/Loadeffect';
import Dialog from '../components/Dialog';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export default function Cashform() {
	const userLogged = useSelector((state) => state.users.value.attributes);
	// const hq = userLogged.nickname.split(',').includes('HQ');
	const location = useLocation();
	const navigate = useNavigate();
	const dis = location.state.action === 'view' ? true : false;
	const currentMonth = location.state.currentMonth;
	const userVal = useSelector((state) => state.users);
	const zoneInfo = userVal.zone;
	const hq = userVal.hq;
	const userName = userVal.userName;
	const [storeId, setStoreId] = useState([userName]);
	const [storeName, setStoreName] = useState([userName]);
	// const storeId = userLogged.nickname.includes(',') ? userLogged.nickname.split(',') : [userLogged.nickname];
	// const storeName = userLogged.name.includes(',') ? userLogged.name.split(',') : [userLogged.name];
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const val = JSON.parse(location.state.route).row;
	const action = JSON.parse(val.author)['AM'].pop() && JSON.parse(val.author)['PM'].pop() ? 'Edit' : 'Create';
	const [valid, setValid] = useState(true);

	const col = [
		{ field: 'store_id', headerName: '門店ID' },
		{ field: 'store_name', headerName: '門店' },
		{ field: 'hours', headerName: '時數' },
		{ field: 'panda_go', headerName: 'PANDAGO' },
		{ field: 'correction', headerName: '更正' },
		{ field: 'daily_total_expense', headerName: '日總支出' },
		{ field: 'daily_total_sales', headerName: '門市現金' },
		{ field: 'author', headerName: '人員' },
		{ field: 'deposit_amount', headerName: '存入金額' },
		{ field: 'date', headerName: '日期' },
		{ field: 'year', headerName: '年份' },
		{ field: 'month', headerName: '月份' },
		{ field: 'uber_cash', headerName: 'UBER現金', hidden: !hq },
		{ field: 'cloud_revenue', headerName: '雲端營收', hidden: !hq },
		{ field: '日總收入', headerName: '日總收入', hidden: !hq },
		{ field: '差額', headerName: '差額', hidden: !hq },
		{ field: 'uber', headerName: 'UBER', hidden: !hq },
		{ field: 'foodpanda', headerName: '熊貓', hidden: !hq },
		{ field: 'linepay', headerName: 'LINEPAY', hidden: !hq },
		{ field: 'jko', headerName: '街口支付', hidden: !hq },
		{ field: 'mace_online', headerName: '麻吉線上金流', hidden: !hq },
		{ field: 'ipass', headerName: '一卡通', hidden: !hq },
		{ field: 'uu_card', headerName: '悠遊卡', hidden: !hq },
		{ field: 'credit_card', headerName: '信用卡', hidden: !hq },
		{ field: 'pxpay', headerName: '全支付', hidden: !hq },
		{ field: '應存入金額', headerName: '應存入金額', hidden: !hq },
		// {field:'deposit_date',headerName:'存款日期'},
		{ field: '存入差額', headerName: '存入差額', hidden: !hq },
		// {field:'createdAt',headerName:'建立日期'},
		{ field: 'profitability', headerName: '產值', hidden: !hq },
		// {field:'editor',headerName:'修改人員'},
	];
	const usedDates = location.state.date ? JSON.parse(location.state.date) : null;
	const [allStoreId, updateAllStoreId] = useState([]);
	const [allStoreName, updateAllStoreName] = useState([]);
	const [duplicate, setDuplicate] = useState(false);
	const authorVal = {
		AM: JSON.parse(val.author)['AM'],
		PM: JSON.parse(val.author)['PM'],
		Edit: JSON.parse(val.author)['Edit'],
	};
	const oldInvoices = val.receipt.map((item) => JSON.parse(item));
	const posInvoice = val.pos_receipt.map((item) => JSON.parse(item));
	const posUploaded = posInvoice[0].InvoiceNo ? true : false;
	oldInvoices.sort((a, b) => a.InvoiceNo.localeCompare(b.InvoiceNo)); //sort invoices by InvoiceNo
	const [allInvoice, setAllInvoice] = useState(oldInvoices);
	const today = moment(new Date()).format('MM/DD/YYYY');
	const [dialog, setDialog] = useState(false);
	const [cancelDialog, setCancelDialog] = useState(false);
	const [preInvoice, setPreInvoice] = useState(oldInvoices);

	const openDialog = () => setDialog(!dialog);

	const handleCancel = () => (!dis ? setCancelDialog(true) : setDialog(!dialog));

	const handleEnter = () => {
		allInvoice.sort((a, b) => a.InvoiceNo.localeCompare(b.InvoiceNo)); //sort invoices by InvoiceNo
		setDialog(!dialog);
		if (allInvoice.length === 1 && !allInvoice[0].InvoiceNo) {
			setAllInvoice([{ InvoiceNo: '', TotalAmount: 0, uploadDate: today, date: rows.date || '' }]);
		} else {
			setAllInvoice((pre) => pre.filter((p) => p.InvoiceNo || p.TotalAmount !== 0));
		}
		let preInvce = JSON.parse(JSON.stringify(allInvoice));
		setPreInvoice(preInvce);
	};

	const confirmCancel = () => {
		setDialog(!dialog);
		rows.receipt = [{ InvoiceNo: '', TotalAmount: 0, uploadDate: today, date: rows.date || '' }];
		setDuplicate(false);
		setCancelDialog(false);
		setAllInvoice(preInvoice); //set to last status
	};

	const closeCancelDialog = () => setCancelDialog(false);

	const handleAddInput = () => {
		setAllInvoice([...allInvoice, { InvoiceNo: '', TotalAmount: 0, uploadDate: today, date: rows.date || '' }]);
		rows.receipt.push({ InvoiceNo: '', TotalAmount: 0, uploadDate: today, date: rows.date || '' });
	};

	const handleInvoice = (type, event, index) => {
		let newVal = [...allInvoice];
		let { name, value } = event.target;
		let invoiceList = newVal.map((n) => n.InvoiceNo);
		if (type === 'edit') {
			if (name === 'TotalAmount') {
				newVal[index][name] = parseInt(value) || 0;
			} else {
				if (invoiceList.includes(value)) {
					newVal[index][name] = value.toUpperCase();
					setDuplicate(true);
				} else {
					newVal[index][name] = value.toUpperCase();
					setDuplicate(false);
				}
			}
			newVal[index].uploadDate = today;
			newVal[index].date = rows.date;
		} else {
			newVal.splice(index, 1);
			if (newVal.length === 0) {
				newVal.push({ InvoiceNo: '', TotalAmount: '', uploadDate: today, date: rows.date || '' });
			}
		}
		setAllInvoice(newVal);
		rows.receipt = newVal;
	};

	useEffect(() => {
		getInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeId, storeName]);

	useEffect(() => {
		allStore().then((list) => {
			updateAllStoreId(list[0]);
			updateAllStoreName(list[1]);
		});
		getStoreIds(hq, zoneInfo, userName, '/audit', 'id').then((store) => {
			let sid = hq ? store : storeId;
			setStoreId(sid);
		});
		getStoreIds(hq, zoneInfo, userName, '/audit', 'name').then((store) => {
			let sName = hq ? store : storeName;
			setStoreName(sName);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getInfo() {
		setOpen(true);
		if (typeof val.correction === 'string') {
			val.correction = JSON.parse(val.correction);
		}
		if (typeof val.daily_total_expense === 'string') {
			val.daily_total_expense = JSON.parse(val.daily_total_expense);
		}
		if (typeof val.daily_total_sales === 'string') {
			val.daily_total_sales = JSON.parse(val.daily_total_sales);
		}
		if (typeof val.author === 'string') {
			val.author = JSON.parse(val.author);
			val.author['AM'] = val.author['AM'].pop() || '';
			val.author['PM'] = val.author['PM'].pop() || '';
			val.author['Edit'] = '';
			delete val.editor;
		}
		if (val.store_id === '') {
			val.store_id = storeId[0];
		}
		if (val.store_name === '' && userLogged) {
			val.store_name = storeName[0];
		}
		val.receipt = oldInvoices;
		val.pos_receipt = posInvoice;
		let initialValid =
			(action === 'Edit' && val['author'] && val['author']['Edit'] === '') ||
			(action === 'Create' &&
				val['author'] &&
				val['author']['AM'] === '' &&
				val['author'] &&
				val['author']['PM'] === '') ||
			val['store_id'] === '' ||
			val['store_name'] === '' ||
			val['date'] === '';
		setValid(initialValid);
		setRows(val);
		setOpen(false);
	}

	const goBack = () => navigate('/cash', { state: { name: '每日收支', currentMonth: currentMonth } });

	const handleSubmit = async (event) => {
		event.preventDefault();
		setOpen(true);
		let newInvoices = allInvoice.map((item) => JSON.stringify(item));
		let create = {
			correction: JSON.stringify(rows.correction),
			daily_total_expense: JSON.stringify(rows['daily_total_expense']),
			daily_total_sales: JSON.stringify(rows['daily_total_sales']),
			author: JSON.stringify({
				AM: rows['author']['AM'] === '' ? [...authorVal['AM']] : [...authorVal['AM'], rows['author']['AM']], //must have old data and new data in the array
				PM: rows['author']['PM'] === '' ? [...authorVal['PM']] : [...authorVal['PM'], rows['author']['PM']],
				Edit: rows['author']['Edit'] === '' ? [...authorVal['Edit']] : [...authorVal['Edit'], rows['author']['Edit']],
			}),
			store_id: rows['store_id'],
			store_name: rows['store_name'],
			date: rows['date'],
			month: rows['month'],
			year: rows['year'],
			hours: rows['hours'],
			receipt: newInvoices,
			panda_go: rows['panda_go'],
			deposit_amount: rows['deposit_amount'],
		};
		if (!val['id']) {
			await createCash(create);
		} else {
			create['_version'] = rows._version;
			create['id'] = rows.id;
			await updateCash(create);
		}
		setOpen(false);
		navigate('/cash', { state: { name: '每日收支' } });
	};

	const getInput = (e, key) => {
		let newVal = { ...rows };
		let val;
		switch (key) {
			case 'date':
				if (e) {
					val = moment(new Date(e._d)).format('MM/DD/YYYY');
					newVal[key] = val || '';
					newVal['year'] = val.split('/')[2];
					newVal['month'] = val.split('/')[0];
					setAllInvoice((pre) => pre.map((p) => (p.date = val))); //set date for receipts when new cashForm is created
				} else {
					newVal[key] = '';
				}
				break;
			case 'store_id':
			case 'store_name':
				val = e.target.value;
				newVal[key] = val;
				if (val === '') {
					newVal['store_name'] = '';
					newVal['store_id'] = '';
				}
				if (userLogged.nickname === 'HQ') {
					if (key === 'store_id') {
						newVal[key] = val;
						newVal['store_name'] = allStoreId.includes(val)
							? allStoreName[allStoreId.indexOf(val)]
							: newVal['store_name'];
					} else {
						newVal[key] = val;
						newVal['store_id'] = allStoreName.includes(val)
							? allStoreId[allStoreName.indexOf(val)]
							: newVal['store_id'];
					}
				} else {
					if (key === 'store_id') {
						newVal['store_name'] = storeId.includes(val) ? storeName[storeId.indexOf(val)] : val;
					} else {
						newVal['store_id'] = storeName.includes(val) ? storeId[storeName.indexOf(val)] : val;
					}
				}
				break;
			case 'author_AM':
				val = e.target.value;
				newVal['author']['AM'] = val || '';
				break;
			case 'author_PM':
				val = e.target.value;
				newVal['author']['PM'] = val || '';
				break;
			case 'editor':
				val = e.target.value;
				newVal['author']['Edit'] = val || '';
				break;
			case 'hours':
				val = parseInt(e.target.value);
				newVal[key] = val || 0;
				newVal['profitability'] = parseInt(newVal['日總收入'] / val);
				break;
			default:
				let cate = '';
				let time = '';
				val = parseInt(e.target.value);
				if (key !== 'panda_go' && key !== 'deposit_amount') {
					if (key.includes('correct')) {
						cate = key.split('_')[0];
						time = key.split('_')[1];
					} else {
						cate = key.slice(0, key.lastIndexOf('_'));
						time = key.split('_')[3];
					}
					newVal[cate][time] = val || 0;
					newVal[cate]['total'] = newVal[cate]['AM'] + newVal[cate]['PM'];
				} else {
					newVal[key] = val || 0;
				}
				newVal['日總收入'] =
					newVal['daily_total_expense']['total'] +
					newVal['daily_total_sales']['total'] +
					newVal['uber'] +
					newVal['foodpanda'] +
					newVal['linepay'] +
					newVal['jko'] +
					newVal['mace_online'] +
					newVal['ipass'] +
					newVal['uu_card'] +
					newVal['credit_card'] +
					newVal['pxpay'] +
					newVal['panda_go'] +
					newVal['uber_cash'];
				newVal['差額'] = -(newVal['cloud_revenue'] - newVal['correction']['total'] - newVal['日總收入']);
				newVal['應存入金額'] =
					newVal['cloud_revenue'] -
					newVal['correction']['total'] -
					newVal['daily_total_expense']['total'] -
					newVal['uber'] -
					newVal['foodpanda'] -
					newVal['linepay'] -
					newVal['jko'] -
					newVal['mace_online'] -
					newVal['ipass'] -
					newVal['uu_card'] -
					newVal['credit_card'] -
					newVal['pxpay'] -
					newVal['panda_go'] +
					newVal['uber_cash'];
				newVal['存入差額'] = newVal['deposit_amount'] - newVal['應存入金額'];
				newVal['profitability'] = parseInt(newVal['日總收入'] / newVal['hours']);
				break;
		}
		if (
			(action === 'Edit' && newVal['author']['Edit'] === '') ||
			(action === 'Create' && !newVal['author']['AM'] && !newVal['author']['PM']) ||
			newVal['store_id'] === '' ||
			newVal['store_name'] === '' ||
			newVal['date'] === ''
		) {
			setValid(true);
		} else {
			setValid(false);
		}
		setRows(newVal);
	};

	const disabledDate = (date) => {
		let oldStore = '';
		usedDates.map((item, i) =>
			item.store_name === rows['store_name']
				? (oldStore = usedDates[i].usedDate.includes(date.format('MM/DD/YYYY')))
				: null
		);
		return oldStore;
	};

	function DContent() {
		let difference = compareInvoice(posInvoice, allInvoice);
		posInvoice.sort((a, b) => a.InvoiceNo.localeCompare(b.InvoiceNo));
		return (
			<Box>
				<Grid container>
					<Grid item md={5} xs={12} hidden={!posUploaded} key={'POSInvoice'}>
						<Typography px={2} fontSize={13} fontWeight='bold'>
							POS作廢發票:
						</Typography>
						<InvoiceTitle />
						{posInvoice.map((i, index) => (
							<Grid container px={2} pb={1.9} key={'POScontainer' + index}>
								<Grid item md={7} xs={5} fontSize={13} className='input_container'>
									{i.InvoiceNo}
								</Grid>
								<Grid item md={5} xs={7} fontSize={13} className='input_container'>
									{i.TotalAmount ? i.TotalAmount : ''}
								</Grid>
							</Grid>
						))}
					</Grid>
					<Grid item md={!posUploaded ? 12 : 7} xs={12} key={'storeInvoice'}>
						<Box>
							<Grid container>
								<Grid item md={5}>
									<Typography px={2} fontSize={13} fontWeight='bold'>
										門市作廢發票:
									</Typography>
								</Grid>
								<Grid item md={7}>
									<Typography px={2} fontSize={12} fontWeight='bold' color='error'>
										(發票共10碼:AA12345678)
									</Typography>
								</Grid>
							</Grid>
						</Box>
						<InvoiceTitle />
						{allInvoice.map((i, index) => (
							<Grid container px={1} key={'storecontainer' + index}>
								<Grid sx={{ px: 1 }} item md={5} xs={5} className='input_container'>
									<TextField
										variant='standard'
										name='InvoiceNo'
										type='text'
										disabled={dis}
										inputProps={{ maxLength: 10, style: { fontSize: 13 } }}
										value={i['InvoiceNo'] || ''}
										onChange={(event) => handleInvoice('edit', event, index)}
									/>
								</Grid>
								<Grid sx={{ px: 1 }} item md={4} xs={4} className='input_container'>
									<TextField
										variant='standard'
										name='TotalAmount'
										type='number'
										disabled={dis}
										inputProps={{ style: { fontSize: 13 } }}
										value={i['TotalAmount'] === 0 ? '' : i['TotalAmount']}
										onFocus={(e) => e.target.select()}
										onChange={(event) => handleInvoice('edit', event, index)}
									/>
								</Grid>
								<Grid item md={3} xs={3}>
									<IconButton
										size='small'
										variant='outlined'
										disabled={dis}
										onClick={(event) => handleInvoice('delete', event, index)}>
										<CancelIcon />
									</IconButton>
									{index === allInvoice.length - 1 && (
										<IconButton size='small' variant='outlined' disabled={dis} onClick={() => handleAddInput()}>
											<AddCircleIcon />
										</IconButton>
									)}
								</Grid>
							</Grid>
						))}
						<Typography hidden={!duplicate} align='center' color='error' fontSize={13} pb={1}>
							發票號碼已存在!
						</Typography>
					</Grid>
				</Grid>
				<Box px={2} pt={2} hidden={!posUploaded}>
					<Divider></Divider>
					<DifInvoice dif={difference} />
				</Box>
			</Box>
		);
	}

	return (
		<>
			<Loadeffect state={open} />
			<Grid container component='form' onSubmit={handleSubmit}>
				<Grid item md={12} xs={12} px={1.5} display='flex' justifyContent='space-between'>
					<Grid item md={1} xs={4} pb={2}>
						<Button variant='contained' startIcon={<ArrowBackIosIcon />} onClick={goBack}>
							返回
						</Button>
					</Grid>
					<Grid item md={8} xs={4} fontWeight='bold'>
						{rows['store_name']} - {rows['date']}
					</Grid>
					<Grid item md={1} xs={4}>
						<Button type='submit' color='success' variant='contained' startIcon={<SaveIcon />} disabled={dis || valid}>
							儲存
						</Button>
					</Grid>
				</Grid>
				<Grid item md={12} xs={12} px={1.5} display='flex' justifyContent='space-between'>
					<Grid item md={2} xs={6} pb={2} textAlign='left'>
						<Chip
							variant='outlined'
							icon={dis ? <RemoveRedEyeIcon /> : <EditIcon />}
							label={dis ? '檢視模式' : '編輯模式'}
							color={dis ? 'error' : 'success'}
						/>
					</Grid>
					<Grid item md={10} xs={6} pb={2} pr={{ xs: 1, md: 0 }} textAlign='right'>
						<Button variant='contained' color='secondary' onClick={openDialog}>
							作廢發票
						</Button>
						<Dialog
							dialog={dialog}
							title={'發票明細'}
							content={DContent()}
							cancel={'取消'}
							submit={'儲存'}
							handleClose={handleCancel}
							handleSubmit={handleEnter}
							showBtn={true}
							disable={duplicate}
							fullWidth={false}
							showSub={!dis}
						/>
						<Dialog
							dialog={cancelDialog}
							title={'此修改將不被儲存'}
							content={'確定?'}
							cancel={'返回'}
							submit={'確定'}
							handleClose={closeCancelDialog}
							handleSubmit={confirmCancel}
							showBtn={true}
							disable={false}
							fullWidth={false}
							showSub={true}
						/>
					</Grid>
				</Grid>
				{col
					.filter((f) => f.hidden === undefined || f.hidden === false)
					.map((c, index) => {
						let key = c.field;
						let valDis = true;
						if (
							(key === 'store_id' && val['store_id'] === '') ||
							(key === 'store_name' && val['store_name'] === '') ||
							(key === 'date' && val['date'] === '')
						) {
							valDis = false;
						} else if (
							key === 'correction' ||
							key === 'daily_total_expense' ||
							key === 'daily_total_sales' ||
							key === 'author' ||
							key === 'hours' ||
							key === 'panda_go' ||
							key === 'deposit_amount'
						) {
							valDis = false;
						}
						return (
							<Grid item xs={6} md={3} key={index} mb={2} sx={{ px: 2 }} style={{ textAlign: 'left' }}>
								<Box fontSize={15}>{c.headerName}</Box>
								{(() => {
									switch (key) {
										case 'date':
											if (val[key] === '') {
												return (
													<>
														<DemoContainer components={['DatePicker']} sx={{ paddingTop: '0px' }}>
															<DatePicker
																shouldDisableDate={disabledDate}
																disableFuture
																onChange={(e) => getInput(e, key)}
																slotProps={{
																	textField: {
																		required: val[key] === '',
																		size: 'small',
																	},
																}}
																dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')}
																localeText={{
																	datePickerToolbarTitle: '選擇日期',
																	cancelButtonLabel: '取消',
																	okButtonLabel: '確認',
																}}
															/>
														</DemoContainer>
														<Box hidden={dis || rows[key] !== ''}>
															<Typography color='error' fontSize='small'>
																*必填
															</Typography>
														</Box>
													</>
												);
											} else {
												return (
													<TextField
														name={key}
														id={key}
														variant='standard'
														size='medium'
														value={rows[key] ? rows[key] : ''}
														disabled={dis || valDis}></TextField>
												);
											}
										case 'author':
											return (
												<Box paddingTop={1}>
													<Grid container>
														<Grid item md={5} paddingBottom={1} paddingRight={{ xs: 0, md: 1 }}>
															<TextField
																label='早班'
																size='small'
																InputLabelProps={{
																	shrink: true,
																}}
																fullWidth
																name={key + '_AM'}
																id={key + '_AM'}
																value={rows[key] ? rows[key]['AM'] : ''}
																disabled={dis || action === 'Edit'}
																onChange={(e) => getInput(e, key + '_AM')}
															/>
														</Grid>
														<Grid item md={5}>
															<TextField
																label='晚班'
																size='small'
																InputLabelProps={{
																	shrink: true,
																}}
																fullWidth
																name={key + '_PM'}
																id={key + '_PM'}
																value={rows[key] ? rows[key]['PM'] : ''}
																disabled={dis || action === 'Edit'}
																onChange={(e) => getInput(e, key + '_PM')}
															/>
														</Grid>
														<Grid item md={2}>
															<Box
																hidden={
																	dis ||
																	(action === 'Edit' ? true : rows[key] ? rows[key]['AM'] || rows[key]['PM'] : false)
																}>
																<Typography color='error' fontSize='small'>
																	*必填
																</Typography>
															</Box>
														</Grid>
													</Grid>
													<Grid container>
														<Grid item md={10} paddingTop={1} sx={{ fontSize: '13px' }}>
															修改人員:{' '}
															<TextField
																name='editor'
																id='editor'
																variant='standard'
																sx={{ width: 130 }}
																size='medium'
																value={rows['author'] ? rows['author']['Edit'] : ''}
																disabled={dis || action === 'Create'}
																onChange={(e) => getInput(e, 'editor')}></TextField>
														</Grid>
														<Grid
															item
															md={2}
															hidden={
																dis ||
																(action === 'Create' ? true : rows['author'] ? rows['author']['Edit'] !== '' : false)
															}>
															<Typography color='error' fontSize='small'>
																*必填
															</Typography>
														</Grid>
													</Grid>
												</Box>
											);
										case 'store_name':
										case 'store_id':
											return (
												<>
													<Autocomplete
														disabled={dis || valDis}
														freeSolo
														id='combo-box-demo'
														options={
															userLogged.nickname === 'HQ'
																? key === 'store_id'
																	? allStoreId
																	: allStoreName
																: key === 'store_id'
																? storeId
																: storeName
														}
														value={rows[key] ? rows[key] : ''}
														onBlur={(e) => getInput(e, key)}
														fullWidth
														renderInput={(params) => <TextField label='' {...params} size='small' />}
													/>
													<Box hidden={dis || rows[key] !== ''}>
														<Typography color='error' fontSize='small'>
															*必填
														</Typography>
													</Box>
												</>
											);
										case 'correction':
										case 'daily_total_expense':
										case 'daily_total_sales':
											return (
												<Box paddingTop={1}>
													<Grid container>
														<Grid item md={6} paddingBottom={1} paddingRight={{ xs: 0, md: 1 }}>
															<TextField
																style={{}}
																label='早班'
																size='small'
																type='number'
																InputLabelProps={{
																	shrink: true,
																}}
																fullWidth
																name={key + '_AM'}
																id={key + '_AM'}
																value={rows[key] ? rows[key]['AM'] : ''}
																disabled={dis || valDis}
																onChange={(e) => getInput(e, key + '_AM')}
																onFocus={(e) => e.target.select()}
															/>
														</Grid>
														<Grid item md={6}>
															<TextField
																label='晚班'
																size='small'
																type='number'
																InputLabelProps={{
																	shrink: true,
																}}
																fullWidth
																name={key + '_PM'}
																id={key + '_PM'}
																value={rows[key] ? rows[key]['PM'] : ''}
																disabled={dis || valDis}
																onChange={(e) => getInput(e, key + '_PM')}
																onFocus={(e) => e.target.select()}
															/>
														</Grid>
														<Box paddingTop={1} sx={{ fontSize: '13px' }}>
															總額:{' '}
															<TextField
																name={key}
																id={key}
																variant='standard'
																size='medium'
																value={rows[key] ? rows[key]['total'] : ''}
																disabled={true}></TextField>
														</Box>
													</Grid>
												</Box>
											);
										case 'hours':
										case 'panda_go':
										case 'deposit_amount':
											return (
												<TextField
													style={{}}
													size='small'
													type='number'
													fullWidth
													name={key}
													id={key}
													value={rows[key] ? rows[key] : 0}
													disabled={dis || valDis}
													onChange={(e) => getInput(e, key)}
													onFocus={(e) => e.target.select()}
												/>
											);
										default:
											return (
												<TextField
													name={key}
													fullWidth
													id={key}
													variant='standard'
													size='medium'
													value={rows[key] ? rows[key] : ''}
													disabled={valDis}
													onChange={(e) => getInput(e, key)}></TextField>
											);
									}
								})()}
							</Grid>
						);
					})}
			</Grid>
		</>
	);
}
