import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

export const createAudit = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.createAuditForm,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('create audit error', error);
	}
};

export const updateAudit = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.updateAuditForm,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('update audit error', error);
	}
};

export const createCash = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.createCashForm,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('create cashForm error', error);
	}
};

export const updateCash = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.updateCashForm,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('update cashForm error', error);
	}
};

export const createStaff = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.createStaffInfo,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('create staff error', error);
	}
};

export const updateStaff = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.updateStaffInfo,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('update staff error', error);
	}
};

export const createStore = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.createMacuStore,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('create store error', error);
	}
};

export const updateStore = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.updateMacuStore,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('update store error', error);
	}
};

export const updatePermission = async (data) => {
	try {
		const res = await API.graphql({
			query: mutations.updatePermission,
			variables: { input: data },
		});
		return res;
	} catch (error) {
		console.log('update permission error', error);
	}
};
