import { Auth, Cache } from 'aws-amplify';
import store from '../redux/store';
import * as redux from '../redux/authSlice';
import { allStore } from '../utils/requestDelivery';
import { listPermission, getStaffInfo, getStoreBySid } from '../utils/extra';

export const getForcePW = async (userStoreId, staff, userVal, storeid) => {
	let pw = false;
	if (staff === 'HQ') {
		if (storeid.length > 1) {
			try {
				const res = await getStaffInfo({ userName: { eq: userVal.username } });
				const staffStores = res.storeList.map((i) => JSON.parse(i));
				pw = res.forcePW;
				store.dispatch(redux.addStaffStores(staffStores));
			} catch (error) {
				console.log('getError', error);
			}
		} else {
			const res = await allStore();
			store.dispatch(redux.addStaffStores(res[2]));
		}
	} else {
		try {
			const res = await getStoreBySid(userStoreId);
			pw = res.forcePW;
		} catch (error) {
			console.log('getError', error);
		}
	}
	store.dispatch(redux.addForcePW(pw));
	return pw;
};

export const getUserRoute = async () => {
	try {
		let response = await listPermission();
		response.map((r) => (r.permission = JSON.parse(r.permission)));
		store.dispatch(redux.addUserSettings(response));
	} catch (error) {
		console.log('error', error);
	}
};

export const checkAuthenticatedUser = async () => {
	await getUserRoute();
	try {
		const userVal = await Auth.currentAuthenticatedUser({ bypassCache: true });
		const storename = userVal.attributes.name.split(',');
		const storeid = userVal.attributes.nickname.split(',');
		const storelist = storename.map((item, index) => `${item},${storeid[index]}`);
		const staff = storeid[0] === 'HQ' ? 'HQ' : 'store';
		const zone = userVal.attributes.zoneinfo.toLowerCase();
		const pw = await new Promise((resolve) =>
			setTimeout(resolve(getForcePW(storeid[0], staff, userVal, storeid)), 5000)
		);
		store.dispatch(redux.addUser(userVal));
		store.dispatch(redux.addStoreName(storelist[0]));
		store.dispatch(redux.addStoreList(storelist));
		store.dispatch(redux.addZone(zone));
		if (!pw) {
			store.dispatch(redux.addVaildUser(true));
		}
	} catch (error) {
		console.log('please login');
	}
};

export const changePWClick = () => {
	store.dispatch(redux.addForcePW(true));
	store.dispatch(redux.addChangePW(true));
	store.dispatch(redux.addVaildUser(false));
};

export const loggoutUser = (setTitle, navigate) => {
	Cache.clear();
	store.dispatch(redux.reset());
	setTitle('登入');
	navigate('/');
};

export const handleBack = async (navigate) => {
	store.dispatch(redux.addVaildUser(true));
	navigate('/home');
};

export const loggout = async () => {
	try {
		await Auth.signOut();
	} catch (error) {
		console.log('error signing out: ', error);
	}
};

export const setUserRoute = (route, rouLvl, userVal) => {
	const curLvl = rouLvl.filter((item) => item.route === route.route);
	const zoneInfo = userVal.attributes.zoneinfo;
	const userName = userVal.username;
	const permission = curLvl[0].permission[zoneInfo].map((item) => item.toLowerCase()); // change all permission users to lowercase
	// console.log("🚀 ~ setUserRoute ~ permission:", permission)
	const checkRes = route.route && (permission[0] === 'all' || permission.includes(userName));
	// console.log('checkRes, route.route, route.component, route.key, route.name',checkRes, route.route, route.component, route.key, route.name)
	return [checkRes, route.route, route.component, route.key, route.name];
};

export const updateCurRoutes = (val) => store.dispatch(redux.addUserRoutes(val));

export const updateMobile = (val) => store.dispatch(redux.addMobile(val));
