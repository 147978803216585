import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { allStore, Utf8ArrayToStr } from '../utils/requestDelivery';
import { creatDirectAcc } from '../utils/lambda';
import { structureAddress } from '../utils/extra';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

export default function Direct() {
	const { enqueueSnackbar } = useSnackbar();
	const [storeList, setStoreList] = useState([]);
	const [selectedStore, setSelectedStore] = useState({});
	const [address, setAddress] = useState('');

	const snackbar = (msg) => enqueueSnackbar(msg);

	useEffect(() => {
		const getStoreList = async () => {
			const stores = await allStore();
			const validStores = stores[3].filter((item) => item.cid === null);
			setStoreList(validStores);
		};
		getStoreList();
	}, []);

	const handleAddressChange = (e) => setAddress(e.target.value);

	const handleStoreChange = (val) => {
		setAddress(val ? val.address : '');
		setSelectedStore(val);
	};

	const handleformSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const inputVal = {};
		for (const val of data.entries()) {
			inputVal[val[0]] = val[1];
		}
		const { raw } = structureAddress(address);
		const payload = {
			orgName: `麻古茶坊MACU TEA ${selectedStore.name}`,
			orgContact: {
				email: inputVal.email,
				phone_details: {
					phone_number: inputVal.phone,
					country_code: '886',
					subscriber_number: inputVal.phone,
				},
			},
			orgAddress: {
				street1: raw.street + raw.number + raw.floor.trim(),
				street2: '',
				city: raw.city,
				state: raw.state,
				zipcode: inputVal.postcode,
			},
			orgUser: {
				email: inputVal.email,
				first_name: inputVal.last_name,
				last_name: inputVal.first_name,
				phone_details: {
					phone_number: inputVal.phone,
					country_code: '886',
					subscriber_number: inputVal.phone,
				},
			},
			s_ID: selectedStore.sid,
		};
		const res = await creatDirectAcc(payload);
		const result = Utf8ArrayToStr(res.Payload);
		snackbar(JSON.parse(result.body).message);
		e.target.reset();
	};

	return (
		<Container component='main' sx={{ maxWidth: { md: '50vw', xs: '100vw' } }}>
			<CssBaseline />
			<Box component='form' onSubmit={handleformSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
				<Typography variant='h6'>建立 Uber Direct 帳號</Typography>
				<Grid container>
					<Grid item md={6} xs={12} px={1}>
						<Autocomplete fullWidth disablePortal name='store' options={storeList} getOptionLabel={(option) => option.name} onChange={(e, val) => handleStoreChange(val)} isOptionEqualToValue={(option, val) => option.name === val.name} renderInput={(params) => <TextField {...params} label='門市名稱' />} sx={{ mt: 2 }} />
					</Grid>
					<Grid item md={6} xs={12} px={1}>
						<TextField type='text' id='address' value={address} onChange={handleAddressChange} label='門市地址' name='address' sx={{ mt: 2 }} fullWidth required />
					</Grid>
					<Grid item md={6} xs={12} px={1}>
						<TextField type='number' id='postcode' label='郵遞區號' name='postcode' sx={{ mt: 2 }} fullWidth required />
					</Grid>
					<Grid item md={6} xs={12} px={1}>
						<TextField type='text' id='email' label='負責人Email (後台帳號與接收對帳單 Email)' name='email' sx={{ mt: 2 }} fullWidth required />
					</Grid>
					<Grid item md={6} xs={12} px={1}>
						<TextField type='number' id='phone' label='聯絡電話 (填寫格式範例: 09xxxxxxxx)' name='phone' sx={{ mt: 2 }} fullWidth required />
					</Grid>
					<Grid item md={6} xs={12} px={1}>
						<TextField type='text' id='first_name' label='負責人姓氏' name='first_name' sx={{ mt: 2 }} fullWidth required />
					</Grid>
					<Grid item md={6} xs={12} px={1}>
						<TextField type='text' id='last_name' label='負責人名字' name='last_name' sx={{ mt: 2 }} fullWidth required />
					</Grid>
				</Grid>

				<Button type='submit' variant='contained' sx={{ mt: 3, mb: 2 }} color='success'>
					建立帳號
				</Button>
			</Box>
		</Container>
	);
}
