import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, Geo } from 'aws-amplify';
import { listStaff, getStaffInfo, getStoreBySid } from '../utils/extra.js';
import { updateStaff, updateStore, createStore, createStaff } from '../utils/mutations.js';
import { allStore } from '../utils/requestDelivery.js';
import ActionStep from '../components/ActionStep.js';
import CompleteStep from '../components/CompleteStep.js';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function CreateUser({ setOpen }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState();
	const [err, setErr] = useState(false);
	const [role, setRole] = useState();
	const [spList, setSpList] = useState([]);
	const [storeList, setStoreList] = useState([]);
	const [selectedStore, setSelectedStore] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [showPassword, setShowPassword] = useState(false);
	const [prevBtn, setPrevBtn] = useState(false);
	const area = ['北', '中', '南', '總部'];
	const position = ['督導', '區經理', '會計', '總部'];
	const steps = ['選擇部門', '輸入帳號資訊', '完成'];
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => event.preventDefault();

	const searchOptionsWithBiasPosition = {
		countries: ['TWN'],
		maxResults: 3,
	};

	useEffect(() => {
		setOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBack = () => {
		navigate('/home');
		setOpen(false);
	};

	const getSupervisor = async (name) => {
		try {
			const res = await getStaffInfo({ staffName: { eq: name } });
			let { id, _version, storeList } = res;
			return [id, _version, storeList];
		} catch (error) {
			console.log('error', error);
		}
	};

	const createNewUser = async (event) => {
		event.preventDefault();
		setPrevBtn(true);
		const data = new FormData(event.currentTarget);
		let signUpVal = {};
		let name = [];
		let nickname = [];
		for (const pair of data.entries()) {
			signUpVal[pair[0]] = pair[1];
		}
		if (signUpVal.newpassword !== signUpVal.newpassword1) {
			setErr(true);
			setErrMsg('密碼不一致');
		} else if (Object.values(signUpVal).includes('') || (role === '總部' && selectedStore.length < 1)) {
			setErrMsg('所有欄位皆須填寫');
			setErr(false);
		} else {
			setErr(false);
			setErrMsg();
			setLoading(true);
			if (role === '總部') {
				switch (signUpVal.position) {
					case '督導':
						signUpVal.position = 'supervisor';
						break;
					case '經理':
						signUpVal.position = 'manager';
						break;
					case '總部':
						signUpVal.position = 'hq';
						break;
					case '會計':
						signUpVal.position = 'accounting';
						break;
					default:
						break;
				}
				selectedStore.map((s) => {
					s.pages = ['/home'];
					name.push(s.store_name);
					nickname.push(s.store_id);
					return '';
				});
				let nameString = name.toString();
				let nicknameString = nickname.toString();
				try {
					const { user } = await Auth.signUp({
						username: signUpVal.newusername,
						password: signUpVal.newpassword,
						attributes: {
							name: '總部,' + nameString,
							nickname: 'HQ,' + nicknameString,
							address: '台北市內湖區陽光街345巷20號',
							given_name: signUpVal.givenname,
							zoneinfo: signUpVal.position,
						},
					});
					let staffVa = {
						userName: signUpVal.newusername.toLowerCase(),
						staffName: signUpVal.givenname,
						storeList: selectedStore.map((s) => JSON.stringify(s)),
						role: signUpVal.position,
						area: signUpVal.area,
						forcePW: true,
						email: '',
					};
					const staff = await createStaff(staffVa);
					if (staff) {
						let spId = staff.data.createStaffInfo.id;
						let sidList = selectedStore.map((s) => s.store_id);
						sidList.map(async (item) => {
							let res = await getStoreBySid(item);
							let { id, _version } = res;
							let storeVa = {
								id: id,
								_version: _version,
								supervisor_id: spId,
							};
							await updateStore(storeVa);
						});
					}
					if (user) {
						setLoading(false);
						setActiveStep((prevActiveStep) => prevActiveStep + 1);
					}
				} catch (error) {
					console.log('error signing up:', error);
				}
			} else {
				const supervisorId = await getSupervisor(signUpVal.supervisor);
				let res = await Geo.searchByText(signUpVal.address, searchOptionsWithBiasPosition);
				res = res[0].geometry.point;
				const geo = [res[1], res[0]];
				try {
					const { user } = await Auth.signUp({
						username: signUpVal.newusername,
						password: signUpVal.newpassword,
						attributes: {
							name: signUpVal.storename,
							nickname: signUpVal.newusername,
							address: signUpVal.address,
							zoneinfo: 'store',
						},
					});
					let storeVa = {
						address: signUpVal.address,
						phone: signUpVal.phone,
						sid: signUpVal.newusername,
						name: signUpVal.storename,
						supervisor_id: supervisorId[0],
						forcePW: true,
						location: geo,
					};
					await createStore(storeVa);
					let staffVa = {
						id: supervisorId[0],
						_version: supervisorId[1],
						storeList: [
							...supervisorId[2],
							JSON.stringify({ store_id: signUpVal.newusername, store_name: signUpVal.storename, pages: ['/home'] }),
						],
					};
					await updateStaff(staffVa);
					if (user) {
						setLoading(false);
						setActiveStep((prevActiveStep) => prevActiveStep + 1);
					}
				} catch (error) {
					console.log('error signing up:', error);
				}
			}
			setTimeout(() => {
				setPrevBtn(false);
				navigate('/permission');
			}, 3000);
		}
	};

	const preStep = () => {
		if (showPassword) {
			handleClickShowPassword();
		}
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		setSelectedStore([]);
		setErr(false);
		setErrMsg();
	};

	const nextStep = async (e) => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		const role = e.target.innerText;
		setRole(role);
		let list = [];
		let store = {};
		if (role === '總部') {
			try {
				await allStore().then((res) => {
					res[0].map((l, index) => {
						store['store_id'] = l;
						store['store_name'] = res[1][index];
						list.push({ ...store });
						return list;
					});
				});
				setStoreList(list);
			} catch (error) {
				console.log('error', error);
			}
		} else {
			try {
				const res = await listStaff();
				list = res.map((i) => i.staffName);
				setSpList(list);
			} catch (error) {
				console.log('list staff error', error);
			}
		}
	};

	return (
		<Container component='main' sx={{ maxWidth: { md: '50vw', xs: '100vw' } }}>
			<CssBaseline />
			<Stepper
				activeStep={activeStep}
				sx={{
					'& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed':
						{ color: '#e93827' },
					pt: 8,
				}}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			{activeStep === 0 && <ActionStep imgTitle1='總部' imgTitle2='門市' secondStep={nextStep} hideReturn={true} />}
			{activeStep === 1 && (
				<Box
					minWidth={{ md: 500, xs: 300 }}
					pt={1}
					alignSelf='center'
					component='form'
					onSubmit={createNewUser}
					noValidate>
					<Typography pt={1} fontWeight='bold'>
						請輸入【{role}人員】帳號資訊, 所有欄位皆須填寫
					</Typography>
					<Grid container>
						{role === '總部' ? (
							<>
								<Grid item md={3.5} xs={6} pr={1}>
									<TextField
										size='small'
										margin='normal'
										required
										fullWidth
										name='newusername'
										label={'新帳號(手機)'}
										id='newusername'
										autoComplete='off'
									/>
								</Grid>
								<Grid item md={2.5} xs={6} pr={1}>
									<TextField
										size='small'
										margin='normal'
										required
										fullWidth
										name='givenname'
										label={'姓名'}
										id='givenname'
										autoComplete='off'
									/>
								</Grid>
								<Grid item md={3} xs={6} pt={2} pr={1}>
									<FormControl fullWidth size='small'>
										<InputLabel id='area-multiple-name-label'>區域</InputLabel>
										<Select
											labelId='area-simple-select-label'
											id='area-simple-select'
											name='area'
											label='area'
											defaultValue=''
											required>
											{area.map((s) => (
												<MenuItem value={s} key={s}>
													{s}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={3} xs={6} pt={2} pr={1}>
									<FormControl fullWidth size='small'>
										<InputLabel id='position-multiple-name-label'>職位</InputLabel>
										<Select
											labelId='position-simple-select-label'
											id='position-simple-select'
											name='position'
											label='position'
											defaultValue=''
											required>
											{position.map((s) => (
												<MenuItem value={s} key={s}>
													{s}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} pr={1} pt={{ md: 1, xs: 2 }}>
									<Autocomplete
										multiple
										id='store-multiple-select'
										options={storeList}
										disableCloseOnSelect
										getOptionLabel={(option) => option.store_name}
										value={selectedStore}
										onChange={(e, val) => setSelectedStore(val)}
										limitTags={3}
										noOptionsText='載入中...'
										size='small'
										renderOption={(props, option, { selected }) => (
											<li {...props}>
												<Checkbox style={{ marginRight: 8 }} checked={selected} />
												{option.store_name}
											</li>
										)}
										renderInput={(params) => <TextField {...params} label='門店' />}
									/>
								</Grid>
							</>
						) : (
							<>
								<Grid item md={4} xs={6} pr={1}>
									<TextField
										margin='normal'
										size='small'
										required
										fullWidth
										name='storename'
										label='門店名稱'
										id='storename'
										autoComplete='off'
									/>
								</Grid>
								<Grid item md={4} xs={6} pr={1}>
									<TextField
										margin='normal'
										size='small'
										required
										fullWidth
										name='newusername'
										label='新帳號(門店ID)'
										id='newusername'
										autoComplete='off'
									/>
								</Grid>
								<Grid item md={4} xs={6} pr={1}>
									<TextField
										margin='normal'
										size='small'
										required
										fullWidth
										name='phone'
										label='門店電話'
										id='phone'
										autoComplete='off'
									/>
								</Grid>
								<Grid item md={4} xs={6} pr={1} pt={2}>
									<FormControl fullWidth size='small'>
										<InputLabel id='supervisor-multiple-name-label'>督導</InputLabel>
										<Select
											labelId='supervisor-simple-select-label'
											id='supervisor-simple-select'
											name='supervisor'
											label='supervisor'
											defaultValue=''
											MenuProps={{
												PaperProps: {
													style: {
														maxHeight: 300,
														width: 180,
													},
												},
											}}>
											{spList.map((s) => (
												<MenuItem value={s} key={s}>
													{s}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={8} xs={12} pr={1}>
									<TextField
										margin='normal'
										size='small'
										required
										fullWidth
										name='address'
										label='門店地址'
										id='address'
										autoComplete='off'
									/>
								</Grid>
							</>
						)}
						<Grid item xs={6} pt={2} pr={1}>
							<FormControl variant='outlined' sx={{ display: 'block' }} size='small' error={err}>
								<InputLabel htmlFor='outlined-adornment-newpassword'>新密碼</InputLabel>
								<OutlinedInput
									id='outlined-adornment-newpassword'
									type={showPassword ? 'text' : 'password'}
									fullWidth
									required
									name='newpassword'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle newpassword visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label='新密碼'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6} pt={2} pr={1}>
							<FormControl variant='outlined' sx={{ display: 'block' }} size='small' error={err}>
								<InputLabel htmlFor='outlined-adornment-newpassword1'>確認新密碼</InputLabel>
								<OutlinedInput
									id='outlined-adornment-newpassword1'
									type={showPassword ? 'text' : 'password'}
									fullWidth
									required
									name='newpassword1'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle newpassword1 visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label='確認新密碼'
								/>
							</FormControl>
						</Grid>
						<Typography variant='caption'>密碼需要至少8個字元．須包含字母與數字</Typography>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 1, bgcolor: 'black', '&:hover': { bgcolor: 'black' }, '&:press': { bgcolor: 'black' } }}>
							<Typography sx={{ color: 'white', display: !loading ? undefined : 'none' }}>立即註冊</Typography>
							<CircularProgress size={24} sx={{ color: 'white', display: loading ? undefined : 'none' }} />
						</Button>
					</Grid>
				</Box>
			)}
			{activeStep === 2 && <CompleteStep finishTitle='註冊成功！' />}
			<React.Fragment>
				<Box minWidth={{ md: 500, xs: '100%' }} pt={2} mt={1} alignSelf='center'>
					<Button
						color='inherit'
						variant='outlined'
						fullWidth
						onClick={activeStep === 0 ? handleBack : preStep}
						sx={{ mr: 1, display: activeStep === 2 ? 'none' : undefined }}
						disabled={prevBtn}>
						{activeStep === 0 ? '返回' : '上一步'}
					</Button>
				</Box>
			</React.Fragment>
			<Typography color='error' pt={1} fontSize={14}>
				{errMsg}
			</Typography>
		</Container>
	);
}
