import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Storage } from '@aws-amplify/storage';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import { useLocation } from 'react-router-dom';
import { DropzoneDialog } from 'mui-file-dropzone';
import { getAuditContent, allStore } from '../utils/requestDelivery';
import { getStoreIds, getStoreBySid } from '../utils/extra';
import { updateAudit, createAudit } from '../utils/mutations';
import { submitAudit } from '../utils/lambda';
import Dialog from '../components/Dialog';
import Signature from '../components/Signature';
import Loadeffect from '../components/Loadeffect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function Audit() {
	const navigate = useNavigate();
	const location = useLocation();
	const disable = location.state.action === 'view' ? true : false;
	const check = location.state.route && JSON.parse(location.state.route);
	const from = location.state.from;
	const to = location.state.to;
	const oldData = [check && check.row];
	const userLogged = useSelector((state) => state.users.value.attributes);
	const userVal = useSelector((state) => state.users);
	const zoneInfo = userVal.zone;
	const hqStaff = userVal.hq;
	const userName = userVal.userName;
	const master = zoneInfo === 'master';
	const [storeName, setStoreName] = useState(hqStaff ? ['總部'] : [userLogged.name]);
	const [content, setContent] = useState([]);
	const [open, setOpen] = useState(true);
	const [allStoreId, updateAllStoreId] = useState([]);
	const [allStoreName, updateAllStoreName] = useState([]);
	const [curSid, setCurSid] = useState(check ? check.row.store_id : hqStaff ? '' : userName);
	const [viewImage, setViewImage] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState();
	// const [weight, setWeight] = useState(0);
	const [dialog, setDialog] = useState(false);
	const [folder, setFolder] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [resultVal, setResultVal] = useState(check ? oldData[0].Content.map((c) => c.Result) : []);
	const [queIndex, setQueIndex] = useState(['', 0]);
	const [curRoute, setCurRoute] = useState({ no: '', folder: '', golden: false });
	const [imageURL, setImageURL] = useState(null);
	const [imageURL2, setImageURL2] = useState(null);
	const [uploadDialog, setUploadDialog] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [warningDialog, setWarningDialog] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);
	const [forForm, setForForm] = useState([]);
	const [forQue, setForQue] = useState([]);
	const [removeFile, setRemoveFile] = useState({ index: '', name: '' });
	const [loading, setLoading] = useState(false);
	const date = check ? oldData[0].createdAt.replace(/\//g, '') : moment().format('YYYYMMDD');
	// const [signID, setSignID] = useState([]);
	// const [staffID, setStaffID] = useState([]);
	const hqView = { display: hqStaff ? undefined : 'none' };
	const category = [
		...new Set(
			content.map((c) => {
				return c.Category;
			})
		),
	];
	const [formValue, setFormValue] = useState([
		{
			Date: check ? oldData[0].createdAt : moment().format('L'),
			Store: check ? oldData[0].Store : hqStaff ? '' : storeName[0],
			Model: check ? oldData[0].Model : hqStaff ? '' : '業主自評',
			Type: check ? oldData[0].Type : hqStaff ? '' : '業主自評',
			Content: check ? oldData[0].Content : [],
			Final: check ? oldData[0].Final : 0,
			Suggestion: check ? oldData[0].Suggestion : '',
			Improvement: check ? oldData[0].Improvement : '',
			id: check ? oldData[0].id : '',
			Status: '',
			signature: check ? oldData[0].signature : '',
			staffSign: check ? oldData[0].staffSign : '',
		},
	]);

	const filesLimit = 5;

	useEffect(() => {
		setOpen(true);
		getAuditContent().then((res) => {
			res.sort(function (a, b) {
				if (a.Category === '黃金守則' && b.Category !== '黃金守則') {
					return -1; // '黃金守則' comes before other categories
				} else if (a.Category !== '黃金守則' && b.Category === '黃金守則') {
					return 1; // Other categories come after '黃金守則'
				}
				return parseInt(a.Number) - parseInt(b.Number);
			});
			let updateRes = res;
			let contentVal = [];
			if (check === undefined) {
				updateRes.map((u) => {
					u.Result = '';
					u.Weight = 0;
					contentVal.push({ Id: u.id, Details: u.Details, Result: u.Result });
					return '';
				}); //set Result to null;
			} else {
				// let oWeight = 0;
				updateRes.map((u, index) => {
					u.Result = oldData[0].Content[index].Result;
					u.Details = oldData[0].Content[index].Details;
					u['Weight'] =
						u.Category === '黃金守則' ? (u.Result === 'true' ? 0 : u.Partition) : u.Result === 'true' ? u.Partition : 0;
					// if(u.Category==='黃金守則' && u.Result === 'false'){
					// oWeight += u.Partition
					// }
					contentVal.push({ Id: u.id, Details: u.Details, Result: u.Result });
					return '';
				});
				// setWeight(oWeight);
			}
			formValue[0].Content = contentVal;
			setContent(updateRes);
			if (master) {
				allStore().then((list) => {
					updateAllStoreId(list[0]);
					updateAllStoreName(list[1]);
				});
			}
			setOpen(false);
		});
		if (check && check.row.signature && check.row.staffSign) {
			setImageURL(check.row.signature);
			setImageURL2(check.row.staffSign);
		}
		getStoreIds(hqStaff, zoneInfo, userName, '/audit', 'name').then((store) => {
			let sName = hqStaff ? store : storeName;
			setStoreName(sName);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchFiles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [curSid]);

	const fetchFiles = async () => {
		let nextToken = undefined;
		let hasNextPage = true;
		let filesList = [];
		let files = [];
		while (hasNextPage) {
			const res = await Storage.list(`自檢表/${curSid}_${formValue[0].Store}/${date}`, {
				pageSize: 1000,
				nextToken: nextToken,
			});
			filesList = filesList.concat(res.results);
			if (res.hasNextToken) {
				nextToken = res.nextToken;
			} else {
				nextToken = undefined;
			}
			hasNextPage = nextToken ? true : false;
		}
		filesList.map((file) => {
			let name = { key: '', name: '', number: '', folder: '' };
			name.key = file.key;
			name.name = file.key.split('/')[3].split('_').slice(5).join('_');
			name.number = file.key.split('/')[3].split('_')[2];
			name.folder = file.key.split('/')[3].split('_')[3];
			files.push(name);
			return files;
		});

		const formFile = files
			.filter((o) => o.number === 'All')
			.sort((a, b) => {
				if (a.folder === 'Before' && b.folder === 'After') return -1; // 'before' comes first
				if (a.folder === 'After' && b.folder === 'Before') return 1; // 'after' comes second
				return 0; // No change if they're the same
			});
		const queFile = files
			.filter((o) => o.number !== 'All')
			.sort((a, b) => {
				let numberSort = a.number.localeCompare(b.number);
				if (numberSort !== 0) {
					return numberSort; //sort number first
				}
				if (a.folder === 'Before' && b.folder === 'After') return -1;
				if (a.folder === 'After' && b.folder === 'Before') return 1;
				return 0;
			});
		setForForm(formFile);
		setForQue(queFile);
	};

	const back = () => {
		navigate('/audit', { state: { name: '自檢表', from: from, to: to } });
	};

	const discard = () => {
		back();
		if (!check) {
			delAllFiles(); // files will be removed from new form
		}
	};

	const delAllFiles = () => {
		if (forQue.length > 0) {
			forQue.map(async (f) => await Storage.remove(f.key));
		}
		if (forForm.length > 0) {
			forForm.map(async (f) => await Storage.remove(f.key));
		}
	};

	const cellStyle = {
		fontWeight: 'bold',
		backgroundColor: '#B1B4BA',
	};
	const fontSizeStyle = {
		fontSize: 16,
	};
	const fontWeightStyle = {
		fontWeight: 'bold',
	};

	const handleDeleteDialog = () => setDeleteDialog(!deleteDialog);

	const handleImage = () => {
		setViewImage(!viewImage);
		setTimeout(() => {
			setSelectedIndex();
		}, 500);
	};

	const handleUpReset = () => {
		setUploadDialog(!uploadDialog);
		setSelectedFile([]);
		setCurRoute((prev) => {
			prev.folder = '';
			return prev;
		});
	};

	const uploadFile = async () => {
		setLoading(true);
		const time = moment().format('YYMMDDHHMMSS');
		const forQue = curRoute.no !== 'form';
		const folder = curRoute.folder === '修正前' ? 'Before' : 'After';
		const golden = curRoute.golden ? 'A' : 'B';
		try {
			for (const s in selectedFile) {
				const userType = zoneInfo === 'store' ? 'O' : 'S';
				const fileName = forQue
					? `${userType}_${curSid}_${golden}${curRoute.no}_${folder}_${time}_${selectedFile[s].name}`
					: `${userType}_${curSid}_All_${folder}_${time}_${selectedFile[s].name}`;
				await Storage.put(`自檢表/${curSid}_${formValue[0].Store}/${date}/${fileName}`, selectedFile[s]);
			}
			handleUpReset();
			resetFolder();
			fetchFiles();
			setLoading(false);
		} catch (error) {
			console.log('Error uploading file: ', error);
		}
	};

	const confirmDel = async (index) => {
		if (removeFile.type === 'form') {
			setForForm((prev) => {
				let old = [...prev];
				old.splice(index, 1);
				if (old.length === 0) {
					setViewImage(false); //close image dialog when no image
				}
				return old;
			});
		} else {
			setForQue((prev) => {
				let old = [...prev];
				old.splice(index, 1);
				if (old.length === 0) {
					setViewImage(false); //close image dialog when no image
				}
				return old;
			});
		}
		handleDeleteDialog();
		await Storage.remove(removeFile.key);
	};

	const onClickRemove = (file, index, type) => {
		setRemoveFile({ index: index, name: file.name, key: file.key, type: type });
		handleDeleteDialog();
	};

	const DContent = () => (
		<Typography>
			請勿關閉視窗 <CircularProgress size={24} sx={{ color: 'red' }} style={{ verticalAlign: 'bottom' }} />
		</Typography>
	);

	const FormImage = () => {
		const number = queIndex[0] === '黃金守則' ? 'A' + queIndex[1] : 'B' + queIndex[1];
		const globalIndex =
			forQue &&
			forQue
				.map((item, index) => (item.number === number ? index : -1)) // Get index or -1 if not matching
				.filter((index) => index !== -1); // for images from questions
		const imageType = selectedIndex !== undefined ? forForm : forQue;

		return (
			<Swiper
				lazy='true'
				spaceBetween={30}
				navigation={true}
				pagination={{
					type: 'fraction',
				}}
				modules={[Pagination, Navigation]}
				initialSlide={selectedIndex !== undefined ? selectedIndex : globalIndex[0]}
				loop={true}
				style={{
					paddingBottom: 50,
				}}>
				{imageType.map((o, index) => (
					<SwiperSlide key={index}>
						<Box textAlign={'center'}>
							{selectedIndex !== undefined ? (
								<Typography fontSize={16} pb={1}>
									表格檔案 {o.folder === 'Before' ? ' > 修正前 > ' : ' > 修正後 > '}
									{o.name}
								</Typography>
							) : (
								<Typography pb={1}>
									{o.number.startsWith('A') ? '黃金守則 > 第' : '非黃金守則 > 第'}
									{o.number.substring(1, 3)}題{o.folder === 'Before' ? ' > 修正前 > ' : ' > 修正後 > '}
									{o.name}
								</Typography>
							)}
							<Typography fontSize={14}>上傳者: {o.key.split('/')[3].startsWith('S') ? '督導' : '門市'}</Typography>
							<img
								alt={o.name}
								src={`https://macudirect-storage-main05428-main.s3.ap-southeast-1.amazonaws.com/public/${encodeURI(
									decodeURI(o.key)
								)}`}
								width='40%'
								height='40%'
							/>
						</Box>
						<Box textAlign={'center'}>
							<Button
								variant='outlined'
								color='error'
								onClick={() => onClickRemove(o, index, 'que')}
								sx={{ display: (!hqStaff && disable) || curSid === '' ? 'none' : undefined }}>
								刪除此照片
							</Button>
						</Box>
					</SwiperSlide>
				))}
			</Swiper>
		);
	};

	const uploadFolder = (number, golden) => {
		setFolder(!folder);
		setCurRoute((prev) => {
			prev.no = number;
			if (golden) {
				prev.golden = golden;
			}
			return prev;
		});
	};

	const selectFolder = (folder) => {
		setUploadDialog(!uploadDialog);
		setCurRoute((prev) => {
			prev.folder = folder;
			return prev;
		});
	};

	const resetFolder = () => {
		setFolder(!folder);
		setCurRoute((prev) => {
			prev.golden = false;
			prev.no = '';
			return prev;
		});
	};

	const SelectContent = () => {
		const folders = ['修正前', '修正後'];
		return (
			<Stack direction={'row'} margin={2} spacing={6}>
				{folders.map((f) => (
					<Box key={f}>
						<Typography align='center' fontSize={14}>
							{f}
						</Typography>
						<IconButton onClick={() => selectFolder(f)} color={f === '修正前' ? 'default' : 'success'}>
							<FolderCopyOutlinedIcon sx={{ fontSize: 75 }} />
						</IconButton>
					</Box>
				))}
			</Stack>
		);
	};

	const handleSubmit = async (status) => {
		setDialog(true);
		status === 'submit' ? setSubmitLoading(true) : setSaveLoading(true);
		let newContent = formValue[0].Content.map((c) => JSON.stringify(c));
		let sid;
		let supervisor;
		let result;
		try {
			let store = await getStoreBySid(curSid);
			sid = store.sid;
			supervisor = store.supervisor_id;
		} catch (error) {
			console.log('error', error);
		}
		let create = {
			Date: formValue[0].Date,
			year: formValue[0].Date.split('/')[0],
			month: formValue[0].Date.split('/')[1],
			Content: newContent,
			Final: formValue[0].Final,
			Store: formValue[0].Store,
			store_id: sid,
			supervisor_id: supervisor,
			Improvement: formValue[0].Improvement,
			Suggestion: formValue[0].Suggestion,
			Type: formValue[0].Type,
			Model: formValue[0].Model,
			Status: [status + '@' + moment().toISOString()],
			signature: hqStaff ? imageURL : '',
			staffSign: hqStaff ? imageURL2 : '',
			Author: userLogged.given_name,
		};
		if (check) {
			create = Object.assign({ id: check.row.id }, create);
			create._version = check.row._version;
			result = await updateAudit(create);
		} else {
			result = await createAudit(create);
		}
		// const sign = await DataStore.query(MacuStore,signID);
		// await DataStore.save(MacuStore.copyOf(sign, updated => {updated.signature = imageURL}));
		// if (hqStaff) {
		// 	const staffSign = await DataStore.query(MacuStore,staffID);
		// 	await DataStore.save(MacuStore.copyOf(staffSign, updated => {updated.staffSign = imageURL2}));
		// }
		if (status === 'submit' || status === 'working') {
			submitAudit(formValue[0].Store, sid, formValue[0].Date, supervisor, create.Content, status);
		}
		if (result.data !== undefined) {
			back();
			setDialog(false);
			status === 'submit' ? setSubmitLoading(false) : setSaveLoading(false);
		}
	};

	// const getSignature = async (val) => {
	// 	const sign = await DataStore.query(MacuStore,(c) => c.sid.eq(userLogged.nickname.split(',')[storeName.indexOf(val)]));
	// 	if (!check) setImageURL(sign[0].signature);
	// 	setSignID(sign[0].id);
	// }

	// const getStaffSign = async (val) => {
	// 	const sign = await DataStore.query(MacuStore,(c) => c.sid.eq(userLogged.nickname.split(',')[storeName.indexOf(val)]));
	// 	// if (!check) setImageURL2(sign[0].staffSign);
	// 	setImageURL2(sign[0].staffSign)
	// 	setStaffID(sign[0].id);
	// }

	function getInput(e, id, key) {
		let val = e.target.value;
		let contentVal = [];
		let form = [...formValue];
		switch (key) {
			case 'Store':
				if ((master && !allStoreName.includes(val)) || (!master && !storeName.includes(val))) {
					val = '';
				}
				form[0][key] = val;
				let index = master ? allStoreName.indexOf(val) : storeName.indexOf(val);
				let sid = master ? allStoreId[index] : userLogged.nickname.split(',')[index];
				setCurSid(sid);
				setFormValue(form);
				break;
			case 'Type':
			case 'Model':
				form[0][key] = val;
				setFormValue(form); // in order to get the val instantly to check if required shows
				// if (key === 'Store' && val) getSignature(val);
				break;
			case 'Suggestion':
			case 'Improvement':
				formValue[0][key] = val;
				break;
			case 'Details':
				content.map((c) => {
					if (c.id === id) c[key] = val;
					contentVal.push({ Id: c.id, Details: c.Details, Result: c.Result });
					return '';
				});
				formValue[0].Content = contentVal;
				break;
			case 'Result':
				let sum = 0;
				let curWeight = 0;
				let calculate = formValue[0].Final;
				let inputVal = [...content];
				inputVal.map((c) => {
					if (c.id === id) {
						c[key] = val;
					}
					if (c.Category === '黃金守則') {
						c.Weight = c.Result === 'false' ? c.Partition : 0;
					} else {
						c.Weight = c.Result === 'true' ? c.Partition : 0;
						if (c.Result === 'true') {
							sum += c.Partition * 0.1;
						}
					}
					if (c.Category === '黃金守則') {
						curWeight += c.Weight; //count total weight
					}
					contentVal.push({ Id: c.id, Details: c.Details, Result: c.Result });
					return '';
				});
				calculate = parseInt(sum - sum * curWeight * 0.01);
				formValue[0].Final = calculate;
				formValue[0].Content = contentVal;
				setContent(inputVal);
				// setWeight(curWeight);
				setResultVal(inputVal.map((c) => c.Result));
				break;
			default:
				break;
		}
	}

	const ChipContains = ({ i, index }) => (
		<Chip
			label={i.name}
			variant='outlined'
			style={{ margin: 2 }}
			key={index}
			color={i.key.split('/')[3].startsWith('S') ? 'success' : 'default'}
			onDelete={
				(!hqStaff && disable) || curSid === ''
					? null
					: () => {
							onClickRemove(i, index, 'form');
					  }
			}
			onClick={() => {
				setSelectedIndex(index);
				setViewImage(!viewImage);
			}}
		/>
	);

	return (
		<Box>
			<Loadeffect state={open} />
			<Button
				align='left'
				sx={{ display: 'flex', marginBottom: 2 }}
				variant='contained'
				startIcon={<ArrowBackIosIcon />}
				onClick={
					check || (forForm.length === 0 && forQue.length === 0) ? discard : () => setWarningDialog(!warningDialog)
				}>
				返回
			</Button>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
					<TableHead>
						<TableRow>
							<TableCell colSpan={6}>
								<Grid container>
									<Grid item md={5} xs={6}>
										<Chip
											variant='outlined'
											icon={disable ? <RemoveRedEyeIcon /> : <EditIcon />}
											label={disable ? '檢視模式' : '編輯模式'}
											color={disable ? 'error' : 'success'}
										/>
									</Grid>
									<Grid item md={7} xs={6} sx={{ fontWeight: 'bold', fontSize: 18 }}>
										麻古門市營運自主檢查表
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow align={'center'} style={{ fontWeight: 'bold' }}>
							<TableCell colSpan={2} align={'center'} style={{ fontWeight: 'bold' }}>
								日期: {formValue[0].Date}
							</TableCell>
							<TableCell align={'center'} style={{ fontWeight: 'bold' }} sx={{ display: check ? 'none' : undefined }}>
								時間: {moment().format('LT')}
							</TableCell>
							<TableCell align={'center'} style={{ fontWeight: 'bold' }} sx={{ display: check ? 'none' : undefined }}>
								填表人: {userLogged.given_name || formValue[0].Store}
							</TableCell>
							<TableCell colSpan={3} align={'right'} sx={{ paddingTop: 2 }}>
								<Grid container>
									<Grid item>
										<Autocomplete
											freeSolo
											disabled={disable}
											id='combo-box-demo'
											options={master ? allStoreName : storeName}
											value={formValue[0].Store}
											onBlur={(e) => getInput(e, null, 'Store')}
											sx={{ width: 200 }}
											renderInput={(params) => <TextField {...params} label='請選擇門市' size='small' />}
										/>
									</Grid>
									<Grid item>
										{formValue[0].Store ? null : (
											<Typography color='error' align='center' padding={1} style={{ fontSize: 12 }}>
												*必填
											</Typography>
										)}
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={6} align={'left'} style={{ fontWeight: 'bold' }}>
								評核模式:
								<FormControl sx={{ marginLeft: 3 }} disabled={disable}>
									<RadioGroup
										row
										aria-labelledby='demo-row-radio-buttons-group-label'
										name='row-radio-buttons-group'
										value={formValue[0].Model}
										onChange={(e) => getInput(e, null, 'Model')}
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 10,
											},
										}}>
										<FormControlLabel value='通知式' control={<Radio size='small' />} label='通知式' />
										<FormControlLabel
											value='不通知式: 第1次'
											control={<Radio size='small' />}
											label='不通知式: 第1次'
										/>
										<FormControlLabel value='不通知式: 第2次' control={<Radio size='small' />} label='第2次' />
										<FormControlLabel value='不通知式: 第3次' control={<Radio size='small' />} label='第3次' />
										<FormControlLabel value='不通知式: 第4次' control={<Radio size='small' />} label='第4次' />
										<FormControlLabel
											sx={{ display: disable ? undefined : 'none' }}
											value='業主自評'
											control={<Radio size='small' />}
											label='業主自評'
										/>
										{formValue[0].Model ? null : (
											<Typography color='error' style={{ fontSize: 12 }}>
												*必填
											</Typography>
										)}
									</RadioGroup>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={6} align={'left'} style={{ fontWeight: 'bold' }}>
								評核類別:
								<FormControl sx={{ marginLeft: 3 }} disabled={disable}>
									<RadioGroup
										row
										aria-labelledby='demo-controlled-radio-buttons-group'
										name='controlled-radio-buttons-group'
										value={formValue[0].Type}
										key={'type'}
										onChange={(e) => getInput(e, null, 'Type')}
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 10,
											},
										}}>
										<FormControlLabel value='常規評核' control={<Radio size='small' />} label='常規評核' />
										<FormControlLabel value='續約評核' control={<Radio size='small' />} label='續約評核' />
										<FormControlLabel
											sx={{ display: disable ? undefined : 'none' }}
											value='業主自評'
											control={<Radio size='small' />}
											label='業主自評'
										/>
										{formValue[0].Type ? null : (
											<Typography color='error' style={{ fontSize: 12 }}>
												*必填
											</Typography>
										)}
									</RadioGroup>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align={'center'} style={{ width: 20, ...cellStyle }}>
								配分
							</TableCell>
							<TableCell align={'center'} style={{ width: 20, ...cellStyle }}>
								NO.
							</TableCell>
							<TableCell align={'center'} style={{ width: 300, ...cellStyle }}>
								評核內容
							</TableCell>
							<TableCell align={'center'} style={{ width: 260, ...cellStyle }}>
								結果
							</TableCell>
							<TableCell align={'center'} style={{ width: 150, ...cellStyle }}>
								說明
							</TableCell>
							<TableCell align={'center'} style={{ width: 130, ...cellStyle }}>
								檔案
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow key={`category_${category[0]}`}>
							<TableCell colSpan={6} align='center' style={{ ...fontWeightStyle, backgroundColor: '#fff2cc' }}>
								{category[0]}
							</TableCell>
						</TableRow>
						{content.map((c, index) => {
							const fileLength = forQue.filter(
								(f) => f.number === (c.Category === '黃金守則' ? 'A' + c.Number : 'B' + c.Number)
							).length;
							return (
								<React.Fragment key={c.Partition + c.Number}>
									{index > 0 && content[index - 1].Category !== c.Category && (
										<TableRow key={`category_${c.Category}`}>
											<TableCell colSpan={6} align='center' style={{ ...fontWeightStyle, backgroundColor: '#CADAEA' }}>
												{c.Category}
											</TableCell>
										</TableRow>
									)}
									<TableRow key={c.Partition + c.Number}>
										<TableCell align='center'>
											{c.Category === '黃金守則' ? c.Partition + '%' : c.Partition * 0.1}
										</TableCell>
										<TableCell align='center'>{c.Number}</TableCell>
										<TableCell align='left'>{c.Content}</TableCell>
										<TableCell align='center'>
											<Grid container>
												<Grid item md={9}>
													<FormControl size='small' disabled={disable}>
														<RadioGroup
															row
															aria-labelledby='demo-controlled-radio-buttons-group'
															name='controlled-radio-buttons-group'
															value={c.Result}
															key={c.id}
															onChange={(e) => getInput(e, c.id, 'Result')}
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 12,
																},
															}}>
															<FormControlLabel
																value={true}
																control={<Radio size='small' />}
																label={<Typography fontSize={14}>合格</Typography>}
															/>
															<FormControlLabel
																value={false}
																control={<Radio size='small' />}
																label={<Typography fontSize={14}>不合格</Typography>}
																sx={{
																	backgroundColor: disable && c.Result === 'false' && 'yellow',
																	pr: disable && c.Result === 'false' && '1rem',
																}}
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
												<Grid item md={3}>
													{c.Result ? null : (
														<Typography color='error' style={{ fontSize: 12 }}>
															*必填
														</Typography>
													)}
												</Grid>
											</Grid>
										</TableCell>
										<TableCell align='left'>
											<textarea
												name='Details'
												multiline='true'
												rows={3}
												disabled={disable}
												id={c.id}
												size='medium'
												defaultValue={c.Details}
												onChange={(e) => getInput(e, c.id, 'Details')}
												style={{ fontSize: 14, resize: 'none' }}></textarea>
											{/* score:{score},weight:{weight}, individual: {c.Weight} final:{formValue[0].Final} */}
										</TableCell>
										<TableCell align='center'>
											<IconButton
												size='large'
												onClick={() => uploadFolder(c.Number, c.Category === '黃金守則')}
												disabled={(!hqStaff && disable) || !curSid}>
												<UploadIcon />
											</IconButton>
											<IconButton
												onClick={() => {
													setViewImage(!viewImage);
													setQueIndex([c.Category, c.Number]);
												}}
												disabled={fileLength === 0}>
												<FolderCopyOutlinedIcon />
												<Typography pl={0.2}>{fileLength === 0 ? null : fileLength}</Typography>
											</IconButton>
										</TableCell>
									</TableRow>
								</React.Fragment>
							);
						})}
						<TableRow>
							<TableCell colSpan={6} align={'left'}>
								<Box justifyContent={'space-between'} display={'flex'}>
									<Stack direction='row' alignItems={'center'}>
										<Typography fontSize={14} fontWeight={'bold'}>
											表格檔案({forForm.length}):&nbsp;&nbsp;
										</Typography>
										<Button
											variant='outlined'
											color='inherit'
											disabled={(!hqStaff && disable) || !curSid}
											size='small'
											onClick={() => uploadFolder('form', false)}
											startIcon={<CloudUploadIcon />}>
											上傳檔案
										</Button>
									</Stack>

									<Typography fontSize={14} fontWeight={'bold'}>
										題目檔案({forQue.length})
									</Typography>
								</Box>
								<Grid container>
									{['Before', 'After'].map((j, index) => (
										<Grid item xs={12} key={j + index}>
											{j === 'Before' ? '修正前' : '修正後'}:&nbsp;
											{forForm.map(
												(i, index) => i.folder === j && <ChipContains i={i} index={index} key={i + index} />
											)}
										</Grid>
									))}
								</Grid>
								<DropzoneDialog
									acceptedFiles={['image/*']}
									open={uploadDialog}
									fileObjects={selectedFile}
									filesLimit={filesLimit}
									onDrop={(newFileObjs) => setSelectedFile((prev) => prev.concat(newFileObjs))}
									onDelete={(deleteFileObj) =>
										setSelectedFile((prev) => prev.filter((obj) => obj.path !== deleteFileObj.path))
									}
									onClose={() => handleUpReset()}
									onSave={() => uploadFile()}
									getFileAddedMessage={(fileName) => `已添加：${fileName}`}
									getDropRejectMessage={(maxFileSize) => `僅能上傳圖片，檔案尺寸需小於15MB`}
									getFileRemovedMessage={(fileName) => `已移除：${fileName}`}
									getFileLimitExceedMessage={(filesLimit) => `一次最多上傳 ${filesLimit} 個檔案`}
									dialogTitle={`上傳檔案至 > ${
										curRoute.golden ? '黃金守則' : curRoute.no === 'form' ? '表格' : '非黃金守則'
									} ${curRoute.no === 'form' ? '' : ` > 第${curRoute.no}題`} > ${curRoute.folder}資料夾`}
									dropzoneText='點擊或將檔案拖移至此處'
									previewText='已添加的檔案：'
									cancelButtonText='取消'
									submitButtonText={
										loading ? (
											<>
												<CircularProgress size={24} style={{ verticalAlign: 'bottom' }} />
											</>
										) : (
											'確認上傳'
										)
									}
									showPreviews={true}
									showFileNamesInPreview={false}
									previewChipProps={{ color: 'success' }}
									useChipsForPreview={true}
									maxFileSize={15000000} //15MB
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={6} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								總分: {formValue[0].Final}
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={3} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								本次建議事項
							</TableCell>
							<TableCell colSpan={3} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								待改善事項
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={3} align={'center'} style={{ ...fontWeightStyle, ...fontSizeStyle }}>
								<TextField
									multiline
									fullWidth
									rows={4}
									disabled={disable}
									defaultValue={formValue[0].Suggestion}
									onChange={(e) => getInput(e, null, 'Suggestion')}></TextField>
							</TableCell>
							<TableCell colSpan={3} align={'center'} style={{ ...fontWeightStyle, ...fontSizeStyle }}>
								<TextField
									multiline
									fullWidth
									rows={4}
									disabled={disable}
									defaultValue={formValue[0].Improvement}
									onChange={(e) => getInput(e, null, 'Improvement')}></TextField>
							</TableCell>
						</TableRow>
						<TableRow sx={{ display: master ? undefined : 'none' }}>
							<TableCell colSpan={3} align={'center'}>
								<Typography>業主簽名</Typography>
								<Signature disable={disable} signURL={imageURL} handleSignatureDone={(arg) => setImageURL(arg)} />
							</TableCell>
							<TableCell colSpan={3} align={'center'}>
								<Typography>督導簽名</Typography>
								<Signature disable={disable} signURL={imageURL2} handleSignatureDone={(arg) => setImageURL2(arg)} />
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={6} align={'center'}>
								<Button
									value='放棄'
									color='error'
									onClick={
										check || (forForm.length === 0 && forQue.length === 0)
											? discard
											: () => setWarningDialog(!warningDialog)
									}
									startIcon={<DeleteForeverIcon />}
									variant='contained'
									disabled={disable}>
									放棄!
								</Button>
								&nbsp;
								<Button
									value='儲存'
									color='inherit'
									onClick={() => handleSubmit('working')}
									variant='contained'
									startIcon={<SaveIcon />}
									disabled={!formValue[0].Store || disable}>
									<Typography sx={{ display: !saveLoading ? undefined : 'none' }}>儲存</Typography>
									<CircularProgress size={24} sx={{ color: 'white', display: saveLoading ? undefined : 'none' }} />
								</Button>
								&nbsp;
								<Button
									value='提交'
									color='success'
									onClick={() => handleSubmit('submit')}
									variant='contained'
									startIcon={<CloudUploadIcon />}
									disabled={
										!(
											formValue[0].Model &&
											formValue[0].Type &&
											formValue[0].Store &&
											!resultVal.includes('') &&
											resultVal.length > 0
										) || disable
									}>
									<Typography sx={{ display: !submitLoading ? undefined : 'none' }}>提交</Typography>
									<CircularProgress size={24} sx={{ color: 'white', display: submitLoading ? undefined : 'none' }} />
								</Button>
								<Typography
									pt={1}
									display={
										!(
											formValue[0].Model &&
											formValue[0].Type &&
											formValue[0].Store &&
											!resultVal.includes('') &&
											resultVal.length > 0
										)
											? undefined
											: 'none'
									}>
									<Alert severity='error' style={{ fontWeight: 'bold', display: 'inline-flex' }}>
										尚有題目未填
									</Alert>
								</Typography>
								<Dialog dialog={dialog} title={'儲存中...'} content={DContent()} showBtn={false} fullWidth={false} />
								<Dialog
									dialog={deleteDialog}
									title={'即將刪除檔案'}
									content={`確定要刪除檔案-${removeFile.name}嗎`}
									showBtn={true}
									fullWidth={false}
									cancel='取消'
									submit='刪除'
									showSub={true}
									handleClose={handleDeleteDialog}
									handleSubmit={() => confirmDel(removeFile.index)}
								/>
								<Dialog
									dialog={viewImage}
									title={''}
									content={FormImage()}
									showBtn={true}
									fullWidth={false}
									cancel='返回'
									handleClose={handleImage}
								/>
								<Dialog
									dialog={folder}
									title={'請選擇資料夾'}
									content={SelectContent()}
									showBtn={true}
									fullWidth={false}
									cancel='返回'
									handleClose={resetFolder}></Dialog>
								<Dialog
									dialog={warningDialog}
									title={'表格尚未儲存'}
									content={`您的上傳檔案將遺失，確定嗎?`}
									showBtn={true}
									fullWidth={false}
									cancel='繼續修改'
									submit='確定放棄'
									showSub={true}
									handleClose={() => setWarningDialog(!warningDialog)}
									handleSubmit={discard}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
