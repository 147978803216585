import { getStoreBySid, getStaffInfo } from '../utils/extra';
import { updateStore, updateStaff } from '../utils/mutations';

export const getAccVersion = async (qtype, username) => {
	let id, version;
	let msg = 200;
	try {
		let res;
		if (qtype === 'staff') {
			res = await getStaffInfo({ userName: { eq: username } });
		} else {
			res = await getStoreBySid(username);
		}
		if (qtype === 'staff') {
			id = res.id;
			version = res._version;
		} else {
			id = res.id;
			version = res._version;
		}
	} catch (error) {
		msg = error;
	}
	return { id: id, version: version, status: msg };
};

export const updateForcePW = async (qtype, input) => {
	let msg = 200;
	try {
		if (qtype === 'staff') {
			await updateStaff(input);
		} else {
			await updateStore(input);
		}
	} catch (error) {
		msg = error;
	}
	return { status: msg };
};
